import React, { useState, useEffect } from 'react';
import { Grid, Box, Card, CardContent, Typography, CardMedia, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

export default function Cameralist() {
    const [selectedDate, setSelectedDate] = useState(dayjs('2024-10-12')); // Default to today's date
    const [tableData, setTableData] = useState([]);
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');

     // Function to handle search input change
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Filter table data based on search term
  const filteredData = tableData.filter((row) =>
    row.objectId.toLowerCase().includes(searchTerm.toLowerCase())
  );

  


    // Hardcoded camera details
    const cameras = [
        {
            CameraId: 1,
            CameraName: 'Camera 1',
            rtspmeUrl: 'https://rtsp.me/embed/D3fAN7EA/',
            rtspUrl: 'rtsp://example.com/stream1'
        },
        {
            CameraId: 2,
            CameraName: 'Camera 2',
            rtspmeUrl: 'https://rtsp.me/embed/h3QK8Dae',
            rtspUrl: 'rtsp://example.com/stream2'
        },
        {
            
            CameraId: 3,
            CameraName: 'Camera 3',
            rtspmeUrl: 'https://rtsp.me/embed/de5G7kY8',
            rtspUrl: 'rtsp://example.com/stream3'
            
        }
    ];

    const handleClick = (event, camera) => {
        console.log("Button clicked for camera:", camera.CameraName);
        localStorage.setItem("cameraName", camera.CameraName);
        localStorage.setItem("rtspmeUrl", camera.rtspmeUrl);
        localStorage.setItem("rtspUrl", camera.rtspUrl);
        localStorage.setItem("cameraId", camera.CameraId);

        navigate('/Camerainsights', {
            state: {
                cameraName: camera.CameraName,
                rtspmeUrl: camera.rtspmeUrl,
                rtspUrl: camera.rtspUrl
            }
        });
    };

    useEffect(() => {
        // Fetch table data based on selected date
        const fetchDataForDate = (date) => {
            const formattedDate = dayjs(date).format('DD/MM/YYYY');
            if (formattedDate === '09/08/2024') {
                return [
                   
                ];
            } else if (formattedDate === '10/08/2024') {
                return [
                    { id: 1, objectId: 'OBJ121', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/WhatsApp%20Image%202024-08-11%20at%2012.52.31%20AM.jpeg', timeIn: '10:24 AM', timeOut: '10:44 AM', timeSpent: '0h 20m' },
                    { id: 2, objectId: 'KA-04 MK 5619', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/WhatsApp%20Image%202024-08-11%20at%2012.52.17%20AM.jpeg', timeOut: '01:30 PM', timeSpent: '3h 5m' },
                    { id: 3, objectId: 'OBJ123', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/WhatsApp%20Image%202024-08-11%20at%2012.52.03%20AM.jpeg ', timeIn: '10:55 AM', timeOut: '12:35 PM', timeSpent: '1h 40m' },
                    { id: 4, objectId: 'KA-51 AF 1384', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/WhatsApp%20Image%202024-08-11%20at%2012.52.42%20AM.jpeg', timeIn: '11:02 AM', timeOut: '11:38 AM', timeSpent: '0h 36m' },
                    { id: 5, objectId: 'KA-02 MF 5886', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/WhatsApp%20Image%202024-08-11%20at%2012.52.53%20AM.jpeg', timeIn: '11:35 AM', timeOut: '01:34 PM', timeSpent: '1h 59m' },
                    { id: 6, objectId: 'KA-01 MM 5493', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/WhatsApp%20Image%202024-08-11%20at%2012.53.13%20AM.jpeg', timeIn: '12:18 PM', timeOut: '12:32 PM', timeSpent: '0h 14m' },
                    { id: 7, objectId: 'KA-09 MJ 6499', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/WhatsApp%20Image%202024-08-11%20at%2012.53.28%20AM.jpeg', timeIn: '01:15 PM', timeOut: '02:25 PM', timeSpent: '1h 10m' },
                    { id: 8, objectId: 'KA-09 MF 1653', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/WhatsApp%20Image%202024-08-11%20at%2012.53.35%20AM.jpeg', timeIn: '01:42 PM', timeOut: '03:00 PM', timeSpent: '1h 18m' },
                    { id: 9, objectId: 'OBJ129', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/WhatsApp%20Image%202024-08-11%20at%2012.53.42%20AM.jpeg', timeIn: '01:52 PM', timeOut: '03:19 PM', timeSpent: '1h 27m' },
                    { id: 10, objectId: 'KA-09 ME 5733', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/WhatsApp%20Image%202024-08-11%20at%2012.53.47%20AM.jpeg', timeIn: '03:10 PM', timeOut: '04:03 PM', timeSpent: '0h 53m' },
                    { id: 11, objectId: 'KA-13 Z 4058', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/WhatsApp%20Image%202024-08-11%20at%2012.53.57%20AM.jpeg', timeIn: '04:03 PM', timeOut: '04:17 PM', timeSpent: '0h 14m' },
                    { id: 12, objectId: 'KA-05 MT 7115', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/WhatsApp%20Image%202024-08-11%20at%2012.54.14%20AM.jpeg', timeIn: '04:31 PM', timeOut: '06:14 PM', timeSpent: '1h 43m' },
                    { id: 13, objectId: 'OBJ1213', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/WhatsApp%20Image%202024-08-11%20at%2012.54.24%20AM.jpeg ', timeIn: '04:34 PM', timeOut: '05:09 PM', timeSpent: '0h 35m' },
                    { id: 14, objectId: 'OBJ1214', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/WhatsApp%20Image%202024-08-11%20at%2012.54.30%20AM.jpeg', timeIn: '05:21 PM', timeOut: '06:22 PM', timeSpent: '1h 1m' },
                    { id: 15, objectId: 'kA-09 MF 4948', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/WhatsApp%20Image%202024-08-11%20at%2012.54.43%20AM.jpeg', timeIn: '05:35 PM', timeOut: '05:46 PM', timeSpent: '0h 11m' },
                    { id: 16, objectId: 'KA-09 MA 1023', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/WhatsApp%20Image%202024-08-11%20at%2012.54.49%20AM.jpeg', timeIn: '05:38 PM', timeOut: '06:12 PM', timeSpent: '0h 34m' },
                               
                    
                ];
            } 
            else if (formattedDate === '11/08/2024') {
                return [
                    { id: 1, objectId: 'TN 07 CQ 3949', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/WhatsApp%20Image%202024-08-11%20at%2010.15.21%20PM.jpeg', timeIn: '10:01 AM', timeOut: '10:44 AM', timeSpent: '0h 20m' },
{ id: 2, objectId: 'MH 12 SY 5442', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/WhatsApp%20Image%202024-08-11%20at%2010.15.36%20PM.jpeg', timeIn: '11:31 AM',timeOut: '01:30 PM', timeSpent: '3h 5m' },
{ id: 3, objectId: 'KA 45 M 1734', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/WhatsApp%20Image%202024-08-11%20at%2010.15.46%20PM.jpeg', timeIn: '10:55 AM', timeOut: '12:35 PM', timeSpent: '1h 40m' },
{ id: 4, objectId: 'KA 05 NE 5326', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/WhatsApp%20Image%202024-08-11%20at%2010.16.00%20PM.jpeg', timeIn: '11:02 AM', timeOut: '11:38 AM', timeSpent: '0h 36m' },
{ id: 5, objectId: 'KA 09 MF 8706', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/WhatsApp%20Image%202024-08-11%20at%2010.16.25%20PM.jpeg', timeIn: '11:35 AM', timeOut: '01:34 PM', timeSpent: '1h 59m' },
{ id: 6, objectId: 'KA 51 MK 3439', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/WhatsApp%20Image%202024-08-11%20at%2010.16.31%20PM.jpeg', timeIn: '', timeOut: '12:32 PM', timeSpent: '0h 14m' },
{ id: 7, objectId: 'KA 09 MG 6513', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/WhatsApp%20Image%202024-08-11%20at%2010.16.59%20PM.jpeg', timeIn: '01:15 PM', timeOut: '02:25 PM', timeSpent: '1h 10m' },
{ id: 8, objectId: 'KA 12 MA 6755', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/WhatsApp%20Image%202024-08-11%20at%2010.17.27%20PM.jpeg', timeIn: '01:42 PM', timeOut: '03:00 PM', timeSpent: '1h 18m' },
{ id: 9, objectId: 'KA 09 MJ 9054', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/WhatsApp%20Image%202024-08-11%20at%2010.17.47%20PM.jpeg', timeIn: '01:52 PM', timeOut: '03:19 PM', timeSpent: '1h 27m' },
{ id: 10, objectId: 'KA 09 MJ 8748', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/WhatsApp%20Image%202024-08-11%20at%2010.18.18%20PM.jpeg', timeIn: '', timeOut: '04:03 PM', timeSpent: '0h 53m' },
{ id: 11, objectId: 'KA 12 MB 7089', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/WhatsApp%20Image%202024-08-11%20at%2010.18.42%20PM.jpeg', timeIn: '04:03 PM', timeOut: '04:17 PM', timeSpent: '0h 14m' },
{ id: 12, objectId: 'KA 09 MJ 1266', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/WhatsApp%20Image%202024-08-11%20at%2010.18.57%20PM.jpeg', timeIn: '04:31 PM', timeOut: '06:14 PM', timeSpent: '1h 43m' },
{ id: 13, objectId: 'KA 09 MB 3115', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/WhatsApp%20Image%202024-08-11%20at%2010.19.26%20PM.jpeg ', timeIn: '', timeOut: '05:09 PM', timeSpent: '0h 35m' },
{ id: 14, objectId: 'KA 19 MN 1151', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/WhatsApp%20Image%202024-08-11%20at%2010.19.49%20PM.jpeg', timeIn: '05:21 PM', timeOut: '06:22 PM', timeSpent: '1h 1m' },
{ id: 15, objectId: 'KA 09 MD 5352', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/WhatsApp%20Image%202024-08-11%20at%2010.19.57%20PM.jpeg', timeIn: '05:35 PM', timeOut: '05:46 PM', timeSpent: '0h 11m' },
{ id: 16, objectId: 'KA 05 MS 3040', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/WhatsApp%20Image%202024-08-11%20at%2010.20.04%20PM.jpeg', timeIn: '05:38 PM', timeOut: '06:12 PM', timeSpent: '0h 34m' },
        
                ];
            } else if (formattedDate === '12/08/2024') {
                return [
                    { id: 1, objectId: 'KA 09 MJ 8993', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car12024-08-12.jpeg', timeIn: '10:03 AM', timeOut: '11:20 AM', timeSpent: '01 h 17 m' },
                    { id: 2, objectId: 'KA 09 MB 8685', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car22024-08-12.jpeg', timeIn: '12:44 PM', timeOut: '02:03 PM', timeSpent: '01 h 19 m' },
                    { id: 3, objectId: 'KA 09 ME 8797', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car32024-08-12.jpeg', timeIn: '01:55 PM', timeOut: '03:06 PM', timeSpent: '01 h 11 m' },
                    { id: 4, objectId: '24 BH 06 74 A', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car42024-08-12.jpeg', timeIn: '02:08 PM', timeOut: '04:21 PM', timeSpent: '02 h 13 m' },
                    { id: 5, objectId: 'KA 09 NF 9204', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car52024-08-12.jpeg', timeIn: '03:37 PM', timeOut: '04:49 PM', timeSpent: '01 h 12 m' },
                    { id: 6, objectId: 'KA 09 ME 4303', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car62024-08-12.jpeg', timeIn: '05:48 PM', timeOut: '07:02 PM', timeSpent: '01 h 14 m' },
                    { id: 7, objectId: 'KA 09 MB 5568', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car72024-08-12.jpeg', timeIn: '06:17 PM', timeOut: '08:21 PM', timeSpent: '02 h 04 m' },
                    { id: 8, objectId: 'KA 09 MJ 5455', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car82024-08-12.jpeg', timeIn: '06:22 PM', timeOut: '08:09 PM', timeSpent: '01 h 47 m' },
                ];
            }   else if (formattedDate === '13/08/2024') {
                return [
                    { id: 1, objectId: 'KA 09 MF 3012', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car12024-08-13.jpeg', timeIn: '11:18 AM', timeOut: '01:00 PM', timeSpent: '01 h 42 m' },
                    { id: 2, objectId: 'KA 09 ME 0591', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car22024-08-13.jpeg', timeIn: '11:35 AM', timeOut: '01:29 PM', timeSpent: '01 h 54 m' },
                    { id: 3, objectId: 'KA 09 MG 8947', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car32024-08-13.jpeg', timeIn: '01:17 PM', timeOut: '03:42 PM', timeSpent: '02 h 25 m' },
                    { id: 4, objectId: 'KL 31 P 3990', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car42024-08-13.jpeg', timeIn: '02:40 PM', timeOut: '04:08 PM', timeSpent: '01 h 28 m' },
                    { id: 5, objectId: 'KA 09 MK 0699', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car52024-08-13.jpeg', timeIn: '03:00 PM', timeOut: '05:53 PM', timeSpent: '02 h 53 m' },
                    { id: 6, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car62024-08-13.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
                    { id: 7, objectId: 'KA 09 MH 7274', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car72024-08-13.jpeg', timeIn: '04:42 PM', timeOut: '06:53 PM', timeSpent: '02 h 11 m' },
                    { id: 8, objectId: 'KA 09 MH 6060', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car82024-08-13.jpeg', timeIn: '05:32 PM', timeOut: '07:45 PM', timeSpent: '02 h 13 m' },
                    { id: 9, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car92024-08-13.jpeg', timeIn: '05:17 PM', timeOut: '08:15 PM', timeSpent: '02 h 58 m' },
                ];
            
            
            
                        } else if (formattedDate === '14/08/2024') {
                return [
                  { id: 1, objectId: 'KA 03 ME 3935', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car12024-09-14.jpeg', timeIn: '09:00 AM', timeOut: '10:15 AM', timeSpent: '01:15' },
                  { id: 2, objectId: 'kA 09 MJ 5455', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car22024-09-14.jpeg', timeIn: '09:14 AM', timeOut: '10:54 AM', timeSpent: '01:40' },
                  { id: 3, objectId: 'KL 57 N 7759', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car32024-09-14.jpeg', timeIn: '09:38 AM', timeOut: '10:32 AM', timeSpent: '00:54' },
                  { id: 4, objectId: 'KA 09 MC 4177', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car42024-09-14.jpeg', timeIn: '09:50 AM', timeOut: '11:18 AM', timeSpent: '01:28' },
                  { id: 5, objectId: 'KA 09 MD 1366', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car52024-09-14.jpeg', timeIn: '10:17 AM', timeOut: '02:10 PM', timeSpent: '03:53' },
                  { id: 6, objectId: 'KA 55 N 0982', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car62024-09-14.jpeg', timeIn: '11:00 AM', timeOut: '11:55 AM', timeSpent: '00:55' },
                  { id: 7, objectId: 'KA 09 MF 8573', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car72024-09-14.jpeg', timeIn: '11:08 AM', timeOut: '12:27 PM', timeSpent: '01:19' },
                  { id: 8, objectId: 'KA 09 MK 0570', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car82024-09-14.jpeg', timeIn: '11:24 AM', timeOut: '12:56 PM', timeSpent: '01:32' },
                  { id: 9, objectId: 'KA 02 MP 6563', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car92024-09-14.jpeg', timeIn: '11:46 AM', timeOut: '01:11 PM', timeSpent: '01:25' },
                  { id: 10, objectId: 'KA 51 MH 5027', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car102024-09-14.jpeg', timeIn: '12:08 PM', timeOut: '01:33 PM', timeSpent: '01:25' },
                  { id: 11, objectId: 'KA 10 M 2919', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car112024-09-14.jpeg', timeIn: '12:22 PM', timeOut: '01:41 PM', timeSpent: '01:19' },
                  { id: 12, objectId: 'KA 09 MJ 3993', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car122024-09-14.jpeg', timeIn: '12:29 PM', timeOut: '03:54 PM', timeSpent: '03:25' },
                  { id: 13, objectId: 'EXOTIC', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car132024-09-14.jpeg', timeIn: '01:14 PM', timeOut: '04:42 PM', timeSpent: '03:28' },
                  { id: 14, objectId: 'KA 09 Z 9975', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car142024-09-14.jpeg', timeIn: '03:09 PM', timeOut: '04:39 PM', timeSpent: '01:30' },
                  { id: 15, objectId: 'KA 53 MH 2939', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car152024-09-14.jpeg', timeIn: '03:45 PM', timeOut: '05:03 PM', timeSpent: '01:18' },
                  { id: 16, objectId: 'KA 09 MK 2738', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car162024-09-14.jpeg', timeIn: '03:58 PM', timeOut: '05:36 PM', timeSpent: '01:38' },
                  { id: 17, objectId: 'KA 09 MJ 1525', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car172024-09-14.jpeg', timeIn: '04:26 PM', timeOut: '05:50 PM', timeSpent: '01:24' },
                  { id: 18, objectId: 'KA 55 N 0664', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car182024-09-14.jpeg', timeIn: '05:38 PM', timeOut: '06:34 PM', timeSpent: '00:56' },
                  { id: 19, objectId: 'KA 09 MG 1278', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car192024-09-14.jpeg', timeIn: '05:56 PM', timeOut: '07:25 PM', timeSpent: '01:29' },
                  { id: 20, objectId: 'KA 09 MK 0886', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car202024-09-14.jpeg', timeIn: '06:18 PM', timeOut: '07:54 PM', timeSpent: '01:36' },
                  { id: 21, objectId: 'KA 09 MD 9933', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car212024-09-14.jpeg', timeIn: '06:58 PM', timeOut: '08:47 PM', timeSpent: '01:49' },
                  { id: 22, objectId: 'KA 09 MD 8452', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car222024-09-14.jpeg', timeIn: '07:28 PM', timeOut: '08:52 PM', timeSpent: '01:24' },
                  { id: 23, objectId: 'CH 01 BU 6437', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car232024-09-14.jpeg', timeIn: '04:29 PM', timeOut: '', timeSpent: '' }
                ]
                
            } else if (formattedDate === '15/08/2024') {
                return [
                    { id: 1, objectId: 'KA 53 Z 6476', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car12024-08-15.jpeg', timeIn: '09:38 PM', timeOut: '11:22 PM', timeSpent: '01 h 44 m' },
                    { id: 2, objectId: 'KA 02 AE 9999', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car22024-08-15.jpeg', timeIn: '09:54 PM', timeOut: '10:29 PM', timeSpent: '00 h 35 m' },
                    { id: 3, objectId: 'KA 21 Z 1141', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car32024-08-15.jpeg', timeIn: '10:36 AM', timeOut: '12:19 PM', timeSpent: '01 h 43 m' },
                    { id: 4, objectId: 'KL 59 AA 0989', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car42024-08-15.jpeg', timeIn: '12:17 PM', timeOut: '01:45 PM', timeSpent: '01 h 28 m' },
                    { id: 5, objectId: 'KA 09 MH 7143', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car52024-08-15.jpeg', timeIn: '01:00 PM', timeOut: '02:20 PM', timeSpent: '01 h 20 m' },
                    { id: 6, objectId: 'KA 03 NO 3443', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car62024-08-15.jpeg', timeIn: '01:30 PM', timeOut: '03:45 PM', timeSpent: '02 h 15 m' },
                    { id: 7, objectId: 'KA 25 Z 1720', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car72024-08-15.jpeg', timeIn: '02:46 PM', timeOut: '04:33 PM', timeSpent: '01 h 47 m' },
                    { id: 8, objectId: 'KA 09 MH 3880', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car82024-08-15.jpeg', timeIn: '02:54 PM', timeOut: '05:04 PM', timeSpent: '02 h 10 m' },
                    { id: 9, objectId: 'KA 09 NH 9599', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car102024-08-15.jpeg', timeIn: '05:26 PM', timeOut: '06:23 PM', timeSpent: '00 h 57 m' },
                    { id: 10, objectId: 'KA 09 MA 0422', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car1112024-08-15.jpeg', timeIn: '05:55 PM', timeOut: '07:44 PM', timeSpent: '01 h 49 m' },
                ];
            } else if (formattedDate === '17/08/2024') {
                return [
                    { id: 1, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car12024-08-17.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 2, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car22024-08-17.jpeg', timeOut: '', timeSpent: '' },
{ id: 3, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car32024-08-17.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 4, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car42024-08-17.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 5, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car52024-08-17.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 6, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car62024-08-17.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 7, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car72024-08-17.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 8, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car82024-08-17.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 9, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car92024-08-17.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 10, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car102024-08-17.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 11, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car112024-08-17.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 12, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car122024-08-17.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 13, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car132024-08-17.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 14, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car142024-08-17.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 15, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car152024-08-17.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 16, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car162024-08-17.jpeg', timeIn: '', timeOut: '', timeSpent: '' },

			];

            } 
			else if (formattedDate === '18/08/2024') {
                return [
                    { id: 1, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car12024-08-18.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 2, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car22024-08-18.jpeg', timeOut: '', timeSpent: '' },
{ id: 3, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car32024-08-18.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 4, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car42024-08-18.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 5, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car52024-08-18.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 6, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car62024-08-18.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 7, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car72024-08-18.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 8, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car82024-08-18.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 9, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car92024-08-18.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 10, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car102024-08-18.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 11, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car112024-08-18.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 12, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car122024-08-18.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 13, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car132024-08-18.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 14, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car142024-08-18.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 15, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car152024-08-18.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 16, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car162024-08-18.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 17, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car172024-08-18.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 18, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car182024-08-18.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 19, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car192024-08-18.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 20, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car202024-08-18.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 21, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car212024-08-18.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 22, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car222024-08-18.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
			];

            } else if (formattedDate === '19/08/2024') {
                return [
                    { id: 1, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car12024-08-19.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 2, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car22024-08-19.jpeg', timeOut: '', timeSpent: '' },
{ id: 3, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car32024-08-19.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 4, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car42024-08-19.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 5, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car52024-08-19.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 6, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car62024-08-19.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 7, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car72024-08-19.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 8, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car82024-08-19.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 9, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car92024-08-19.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 10, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car102024-08-19.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 11, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car112024-08-19.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
			];

            } else if (formattedDate === '20/08/2024') {
                return [
                    { id: 1, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car12024-08-20.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 2, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car22024-08-20.jpeg', timeOut: '', timeSpent: '' },
{ id: 3, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car32024-08-20.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 4, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car42024-08-20.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 5, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car52024-08-20.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 6, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car62024-08-20.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 7, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car72024-08-20.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 8, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car82024-08-20.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 9, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car92024-08-20.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 10, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car102024-08-20.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 11, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car112024-08-20.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 12, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car122024-08-20.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 13, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car132024-08-20.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 14, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car142024-08-20.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 15, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car152024-08-20.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
			];

            } else if (formattedDate === '21/08/2024') {
                return [
                    { id: 1, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car12024-08-21.jpeg', timeIn: '09:40 AM', timeOut: '10:54 AM', timeSpent: '01 h 14 m' },
                    { id: 2, objectId: 'KA 09 ME 9488', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car22024-08-21.jpeg', timeIn: '09:41 AM', timeOut: '10:39 AM', timeSpent: '00 h 58 m' },
                    { id: 3, objectId: 'KA 05 NL 5892', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car32024-08-21.jpeg', timeIn: '09:44 AM', timeOut: '11:11 AM', timeSpent: '01 h 27 m' },
                    { id: 4, objectId: 'kA 09 MJ 6198', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car42024-08-21.jpeg', timeIn: '10:00 AM', timeOut: '01:38 PM', timeSpent: '03 h 38 m' },
                    { id: 5, objectId: 'KL 45 R 6176', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car52024-08-21.jpeg', timeIn: '10:54 AM', timeOut: '12:01 PM', timeSpent: '01 h 07 m' },
                    { id: 6, objectId: 'kA 05 NE 5326', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car62024-08-21.jpeg', timeIn: '10:57 AM', timeOut: '12:59 PM', timeSpent: '02 h 02 m' },
                    { id: 7, objectId: 'KA 09 N 4219', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car72024-08-21.jpeg', timeIn: '11:41 AM', timeOut: '12:30 PM', timeSpent: '00 h 49 m' },
                    { id: 8, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car82024-08-21.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
                    { id: 9, objectId: 'KA 09 ME 8054', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car92024-08-21.jpeg', timeIn: '01:03 PM', timeOut: '02:50 PM', timeSpent: '01 h 47 m' },
                    { id: 10, objectId: 'KA 09 MJ 5455', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car102024-08-21.jpeg', timeIn: '01:24 PM', timeOut: '03:05 PM', timeSpent: '01 h 41 m' },
                    { id: 11, objectId: 'KA 09 MW 0362', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car112024-08-21.jpeg', timeIn: '01:42 PM', timeOut: '02:41 PM', timeSpent: '00 h 59 m' },
                    { id: 12, objectId: 'KA 09 MC 7776', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car122024-08-21.jpeg', timeIn: '03:45 PM', timeOut: '04:58 PM', timeSpent: '01 h 13 m' },
                    { id: 13, objectId: 'KA 09 MJ 3979', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car132024-08-21.jpeg', timeIn: '04:43 PM', timeOut: '06:30 PM', timeSpent: '01 h 47 m' },
                    { id: 14, objectId: 'KA 19 MH 2936', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car142024-08-21.jpeg', timeIn: '04:59 PM', timeOut: '06:41 PM', timeSpent: '01 h 42 m' },
                    { id: 15, objectId: 'KA 01 MA 1922', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car152024-08-21.jpeg', timeIn: '05:36 PM', timeOut: '06:43 PM', timeSpent: '01 h 07 m' },
                    { id: 16, objectId: 'UP 78 EK 5514', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car162024-08-21.jpeg', timeIn: '05:42 PM', timeOut: '08:03 PM', timeSpent: '02 h 21 m' },
                    { id: 17, objectId: 'KA 09 A 5885', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car172024-08-21.jpeg', timeIn: '06:03 PM', timeOut: '08:01 PM', timeSpent: '01 h 58 m' },
                    { id: 18, objectId: 'KA 09 MD 6451', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car182024-08-21.jpeg', timeIn: '06:57 PM', timeOut: '08:36 PM', timeSpent: '01 h 39 m' },
                    { id: 19, objectId: 'KA 09 MH 4125', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car192024-08-21.jpeg', timeIn: '06:41 PM', timeOut: '09:21 PM', timeSpent: '02 h 40 m' },
                ];
            }
             else if (formattedDate === '22/08/2024') {
                return [
                    { id: 1, objectId: 'KA 09 MK 3145', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car12024-08-22.jpeg', timeIn: '11:10 AM', timeOut: '12:12 PM', timeSpent: '01 h 02 m' },
                    { id: 2, objectId: 'KA 4087', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car22024-08-22.jpeg', timeIn: '11:09 AM', timeOut: '12:34 PM', timeSpent: '01 h 25 m' },
                    { id: 3, objectId: 'KA 09 MG 7107', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car32024-08-22.jpeg', timeIn: '11:57 AM', timeOut: '12:30 PM', timeSpent: '00 h 33 m' },
                    { id: 4, objectId: 'KA 09 MJ 0913', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car42024-08-22.jpeg', timeIn: '11:17 AM', timeOut: '02:33 PM', timeSpent: '03 h 16 m' },
                    { id: 5, objectId: 'KA 09 MC 9868', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car52024-08-22.jpeg', timeIn: '12:06 PM', timeOut: '04:30 PM', timeSpent: '04 h 24 m' },
                    { id: 6, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car62024-08-22.jpeg', timeIn: '03:18 PM', timeOut: '04:22 PM', timeSpent: '01 h 04 m' },
                    { id: 7, objectId: 'KA 09 MK 2809', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car72024-08-22.jpeg', timeIn: '05:08 PM', timeOut: '05:59 PM', timeSpent: '00 h 51 m' },
                    { id: 8, objectId: 'KA 09 MH 5653', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car82024-08-22.jpeg', timeIn: '06:18 PM', timeOut: '08:15 PM', timeSpent: '01 h 57 m' },
                    { id: 9, objectId: 'KA 59 MG 9513', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car92024-08-22.jpeg', timeIn: '06:29 PM', timeOut: '07:44 PM', timeSpent: '01 h 15 m' },
                    { id: 10, objectId: 'KA 51 MH 5833', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car102024-08-22.jpeg', timeIn: '06:32 PM', timeOut: '08:56 PM', timeSpent: '02 h 24 m' },
                    { id: 11, objectId: 'MH 01 CA 0116', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car112024-08-22.jpeg', timeIn: '06:34 PM', timeOut: '09:01 PM', timeSpent: '02 h 27 m' },
                ];
            }
            else if (formattedDate === '06/09/2024') {
              return [
                { id: 1, objectId: 'MN 12 TT 4959', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car12024-09-09.jpeg', timeIn: '09:35 AM', timeOut: '10:23 AM', timeSpent: '48m' },
{ id: 2, objectId: 'KA 09 MJ 2476', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car22024-09-09.jpeg', timeIn: '10:03 AM', timeOut: '12:08 PM', timeSpent: '2h 5m' },
{ id: 3, objectId: 'KA 55 N 3669', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car32024-09-09.jpeg', timeIn: '10:23 AM', timeOut: '11:34 AM', timeSpent: '1h 11m' },
{ id: 4, objectId: 'KA 11 N 1068', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car42024-09-09.jpeg', timeIn: '10:39 AM', timeOut: '11:06 AM', timeSpent: '27m' },
{ id: 5, objectId: 'KA 09 MJ 0690', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car52024-09-09.jpeg', timeIn: '11:56 AM', timeOut: '04:17 PM', timeSpent: '4h 21m' },
{ id: 6, objectId: 'KA 05 MK 9551', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car62024-09-09.jpeg', timeIn: '12:01 PM', timeOut: '02:07 PM', timeSpent: '2h 6m' },
{ id: 7, objectId: 'KA 09 MF 2273', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car72024-09-09.jpeg', timeIn: '12:35 PM', timeOut: '02:45 PM', timeSpent: '2h 10m' },
{ id: 8, objectId: 'KA 09 MF 5323', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car82024-09-09.jpeg', timeIn: '01:03 PM', timeOut: '02:35 PM', timeSpent: '1h 32m' },
{ id: 9, objectId: 'KL 11 BT 8430', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car92024-09-09.jpeg', timeIn: '02:14 PM', timeOut: '04:27 PM', timeSpent: '2h 13m' },
{ id: 10, objectId: 'KA 09 NF 6080', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car102024-09-09.jpeg', timeIn: '02:29 PM', timeOut: '03:48 PM', timeSpent: '1h 19m' },
{ id: 11, objectId: 'KA 09 MF 2061', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car112024-09-09.jpeg', timeIn: '03:05 PM', timeOut: '07:36 PM', timeSpent: '4h 31m' },
{ id: 12, objectId: 'KA 03 AG 4587', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car122024-09-09.jpeg', timeIn: '03:20 PM', timeOut: '05:16 PM', timeSpent: '1h 56m' },
{ id: 13, objectId: 'KA 09 MJ 1533', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car132024-09-09.jpeg', timeIn: '03:36 PM', timeOut: '05:13 PM', timeSpent: '1h 37m' },
{ id: 14, objectId: 'KA 12 MB 8995', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car142024-09-09.jpeg', timeIn: '04:05 PM', timeOut: '06:34 PM', timeSpent: '2h 29m' },
{ id: 15, objectId: 'KA 05 NJ 4623', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car152024-09-09.jpeg', timeIn: '04:47 PM', timeOut: '06:15 PM', timeSpent: '1h 28m' },
{ id: 16, objectId: 'KA 02 NL 2359', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car162024-09-09.jpeg', timeIn: '05:52 PM', timeOut: '06:41 PM', timeSpent: '49m' },
{ id: 17, objectId: 'KA 09 MG 4410', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car172024-09-09.jpeg', timeIn: '06:00 PM', timeOut: '07:46 PM', timeSpent: '1h 46m' },
{ id: 18, objectId: 'KA 09 MF 2466', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car182024-09-09.jpeg', timeIn: '06:41 PM', timeOut: '07:30 PM', timeSpent: '49m' },
{ id: 19, objectId: 'KA 55 M 7328', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car192024-09-09.jpeg', timeIn: '07:44 PM', timeOut: '08:27 PM', timeSpent: '43m' },
{ id: 20, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car202024-09-09.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 21, objectId: 'KA 05 NE 5326', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car212024-09-09.jpeg', timeIn: '06:58 PM', timeOut: '09:05 PM', timeSpent: '2h 7m' },
{ id: 22, objectId: 'KA 09 MJ 3063', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car222024-09-09.jpeg', timeIn: '07:57 PM', timeOut: '09:07 PM', timeSpent: '1h 10m' },

              ];
          }
else if (formattedDate === '07/09/2024') {
              return [
               
                  { id: 1, objectId: 'KA 02 MN 6626', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car12024-09-07.jpeg', timeIn: '09:39 AM', timeOut: '12:25 PM', timeSpent: '2h 46m' },
                  { id: 2, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car22024-09-07.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
                  { id: 3, objectId: 'KA 02 BB 5867', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car32024-09-07.jpeg', timeIn: '10:05 AM', timeOut: '10:52 AM', timeSpent: '47m' },
                  { id: 4, objectId: 'KA 09 MG 8527', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car42024-09-07.jpeg', timeIn: '09:57 AM', timeOut: '12:15 PM', timeSpent: '2h 18m' },
                  { id: 5, objectId: 'KA 09 MJ 5476', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car52024-09-07.jpeg', timeIn: '10:03 AM', timeOut: '11:50 AM', timeSpent: '1h 47m' },
                  { id: 6, objectId: 'KA 09 MF 9880', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car62024-09-07.jpeg', timeIn: '11:12 AM', timeOut: '01:02 PM', timeSpent: '1h 50m' },
                  { id: 7, objectId: 'KA 04 MJ 1091', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car72024-09-07.jpeg', timeIn: '11:00 AM', timeOut: '', timeSpent: '' },
                  { id: 8, objectId: 'KA 51 Z 6425', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car82024-09-07.jpeg', timeIn: '11:40 AM', timeOut: '01:33 PM', timeSpent: '1h 53m' },
                  { id: 9, objectId: 'KA 03 MX 4998', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car92024-09-07.jpeg', timeIn: '02:07 PM', timeOut: '02:40 PM', timeSpent: '33m' },
                  { id: 10, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car102024-09-07.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
                  { id: 11, objectId: 'KA 09 MG 7127', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car112024-09-07.jpeg', timeIn: '03:26 PM', timeOut: '04:23 PM', timeSpent: '57m' },
                  { id: 12, objectId: 'KA 09 MH 2526', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car122024-09-07.jpeg', timeIn: '03:26 PM', timeOut: '05:02 PM', timeSpent: '1h 36m' },
                  { id: 13, objectId: 'KA 06 MU 5672', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car132024-09-07.jpeg', timeIn: '03:54 PM', timeOut: '05:29 PM', timeSpent: '1h 35m' },
                  { id: 14, objectId: 'KA 09 MG 0711', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car142024-09-07.jpeg', timeIn: '04:29 PM', timeOut: '06:02 PM', timeSpent: '1h 33m' },
                  { id: 15, objectId: 'KA 09 MJ 2602', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car152024-09-07.jpeg', timeIn: '04:38 PM', timeOut: '05:51 PM', timeSpent: '1h 13m' },
                  { id: 16, objectId: 'KA 09 ME 6718', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car162024-09-07.jpeg', timeIn: '05:09 PM', timeOut: '06:33 PM', timeSpent: '1h 24m' },
                  { id: 17, objectId: 'KA 09 MH 9669', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car172024-09-07.jpeg', timeIn: '05:37 PM', timeOut: '06:24 PM', timeSpent: '47m' },
                  { id: 18, objectId: 'MH G4 GJ 5877', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car182024-09-07.jpeg', timeIn: '07:22 PM', timeOut: '08:37 PM', timeSpent: '1h 15m' },
                  { id: 19, objectId: 'KA 09 MF 5075', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car192024-09-07.jpeg', timeIn: '07:27 PM', timeOut: '08:57 PM', timeSpent: '1h 30m' },
                  { id: 20, objectId: 'KA 02 P 6563', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car202024-09-07.jpeg', timeIn: '08:29 PM', timeOut: '09:32 PM', timeSpent: '1h 3m' }
              
              
                 
              ];
          }
          
else if (formattedDate === '08/09/2024') {
              return [
                
                {id: 1, objectId: 'KA 12 MB 2862', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car12024-09-08.jpeg', timeIn: '09:04 AM', timeOut: '10:30 AM', timeSpent: '1h 26m' },
                { id: 2, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car22024-09-08.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
                { id: 3, objectId: 'KA 53 MA 0019', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car32024-09-08.jpeg', timeIn: '09:53 AM', timeOut: '11:32 AM', timeSpent: '1h 39m' },
                { id: 4, objectId: 'KA 43 M 3635', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car42024-09-08.jpeg', timeIn: '10:18 AM', timeOut: '11:34 AM', timeSpent: '1h 16m' },
                { id: 5, objectId: 'CG 04 NN 2310', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car52024-09-08.jpeg', timeIn: '12:00 PM', timeOut: '01:36 PM', timeSpent: '1h 36m' },
                { id: 6, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car62024-09-08.jpeg', timeIn: '01:09 PM', timeOut: '02:14 PM', timeSpent: '1h 5m' },
                { id: 7, objectId: 'KA 50 MC 3040', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car72024-09-08.jpeg', timeIn: '01:15 PM', timeOut: '03:35 PM', timeSpent: '2h 20m' },
                { id: 8, objectId: 'KA 09 MH 3008', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car82024-09-08.jpeg', timeIn: '03:29 PM', timeOut: '05:34 PM', timeSpent: '2h 5m' },
                { id: 9, objectId: 'KA 01 MZ 7793', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car92024-09-08.jpeg', timeIn: '02:17 PM', timeOut: '03:36 PM', timeSpent: '1h 19m' },
                { id: 10, objectId: 'KA 03 NJ 6665', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car102024-09-08.jpeg', timeIn: '03:26 PM', timeOut: '03:57 PM', timeSpent: '31m' },
                { id: 11, objectId: 'KA 53 Z 5882', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car112024-09-08.jpeg', timeIn: '04:14 PM', timeOut: '07:19 PM', timeSpent: '3h 5m' },
                { id: 12, objectId: 'KA 09 MG 5755', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car122024-09-08.jpeg', timeIn: '06:29 PM', timeOut: '08:08 PM', timeSpent: '1h 39m' },
                { id: 13, objectId: 'KA 09 MN 8730', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car132024-09-08.jpeg', timeIn: '06:45 PM', timeOut: '08:17 PM', timeSpent: '1h 32m' },
                { id: 14, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car142024-09-08.jpeg', timeIn: '07:40 PM', timeOut: '08:41 PM', timeSpent: '1h 1m' }
                         
              ];
          }
          else if (formattedDate === '09/09/2024') {
            return [
                
              { id: 1, objectId: 'KA 50 MD 6768', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car12024-09-9.jpeg', timeIn: '09:14 AM', timeOut: '10:47 AM', timeSpent: '1h 33m' },
              { id: 2, objectId: 'KA 02 MN 8984', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car22024-09-9.jpeg', timeIn: '09:35 AM', timeOut: '01:01 PM', timeSpent: '3h 26m' },
              { id: 3, objectId: 'KA 09 RE 8621', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car32024-09-9.jpeg', timeIn: '10:13 AM', timeOut: '11:20 AM', timeSpent: '1h 7m' },
              { id: 4, objectId: 'TN 07 DF 5094', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car42024-09-9.jpeg', timeIn: '10:31 AM', timeOut: '12:31 PM', timeSpent: '2h 0m' },
              { id: 5, objectId: 'KA 09 MK 0768', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car52024-09-9.jpeg', timeIn: '11:46 AM', timeOut: '01:17 PM', timeSpent: '1h 31m' },
              { id: 6, objectId: 'KA 02 ME 4627', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car62024-09-9.jpeg', timeIn: '12:13 PM', timeOut: '01:39 PM', timeSpent: '1h 26m' },
              { id: 7, objectId: 'KA 12 MB 6038', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car72024-09-9.jpeg', timeIn: '03:34 PM', timeOut: '04:46 PM', timeSpent: '1h 12m' },
              { id: 8, objectId: 'KA 09 MD 2317', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car82024-09-9.jpeg', timeIn: '05:16 PM', timeOut: '07:55 PM', timeSpent: '2h 39m' },
              { id: 9, objectId: 'KA 09 MK 1252', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car92024-09-9.jpeg', timeIn: '05:45 PM', timeOut: '07:09 PM', timeSpent: '1h 24m' },
              
            ];
        }
else if (formattedDate === '10/09/2024') {
            return [
              


{ id: 1, objectId: 'KA 09 MG 7677', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car12024-09-10.jpeg', timeIn: '08:55 AM', timeOut: '09:54 AM', timeSpent: '59m' },
{ id: 2, objectId: 'KL 13 AY 3875', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car22024-09-10.jpeg', timeIn: '09:16 AM', timeOut: '09:37 AM', timeSpent: '21m' },
{ id: 3, objectId: 'KA 12 MC 0867', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car32024-09-10.jpeg', timeIn: '10:14 AM', timeOut: '11:33 AM', timeSpent: '1h 19m' },
{ id: 4, objectId: 'KA 03 ND 8034', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car42024-09-10.jpeg', timeIn: '01:58 PM', timeOut: '02:55 PM', timeSpent: '57m' },
{ id: 5, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car52024-09-10.jpeg', timeIn: '01:58 PM', timeOut: '02:55 PM', timeSpent: '57m' },
{ id: 6, objectId: 'KA 11 N 6485', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car62024-09-10.jpeg', timeIn: '01:58 PM', timeOut: '02:55 PM', timeSpent: '57m' },
{ id: 7, objectId: 'KA 55 MK 8688', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car72024-09-10.jpeg', timeIn: '02:48 PM', timeOut: '03:43 PM', timeSpent: '55m' },
{ id: 8, objectId: 'KA 67 MA 2574', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car82024-09-10.jpeg', timeIn: '03:45 PM', timeOut: '06:16 PM', timeSpent: '2h 31m' },
{ id: 9, objectId: 'KA 12 MB 5716', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car92024-09-10.jpeg', timeIn: '03:52 PM', timeOut: '04:43 PM', timeSpent: '51m' },
{ id: 10, objectId: 'KA 09 MC 2599', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car102024-09-10.jpeg', timeIn: '03:53 PM', timeOut: '05:24 PM', timeSpent: '1h 31m' },
{ id: 11, objectId: 'KA 09 MG 2227', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car112024-09-10.jpeg', timeIn: '04:05 PM', timeOut: '07:26 PM', timeSpent: '3h 21m' },
{ id: 12, objectId: 'KA 09 ME 7043', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car122024-09-10.jpeg', timeIn: '04:38 PM', timeOut: '05:15 PM', timeSpent: '37m' },
{ id: 13, objectId: 'KA 09 MF 5353', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car132024-09-10.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 14, objectId: 'KA 06 M 6797', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car142024-09-10.jpeg', timeIn: '06:20 PM', timeOut: '07:26 PM', timeSpent: '1h 6m' },
{ id: 15, objectId: 'KA 04 MP 6650', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car152024-09-10.jpeg', timeIn: '06:49 PM', timeOut: '08:05 PM', timeSpent: '1h 16m' },
{ id: 16, objectId: 'KA 09 MJ 1525', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car162024-09-10.jpeg', timeIn: '06:54 PM', timeOut: '08:55 PM', timeSpent: '2h 1m' },
{ id: 17, objectId: 'KA 09 MJ 2932', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car172024-09-10.jpeg', timeIn: '07:56 PM', timeOut: '09:02 PM', timeSpent: '1h 6m' },
{ id: 18, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car182024-09-10.jpeg', timeIn: '', timeOut: '', timeSpent: '' }

            ];
        }           
        else if (formattedDate === '11/09/2024') {
          return [
{ id: 1, objectId: 'KA 05 MU 3286', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car12024-09-11.jpeg', timeIn: '09:15 AM', timeOut: '10:35 AM', timeSpent: '1h 20m' },
{ id: 2, objectId: 'KA 09 MK 1108', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car22024-09-11.jpeg', timeIn: '09:27 AM', timeOut: '11:59 AM', timeSpent: '2h 32m' },
{ id: 3, objectId: 'KA 22 P 1895', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car32024-09-11.jpeg', timeIn: '09:56 AM', timeOut: '12:41 PM', timeSpent: '2h 45m' },
{ id: 4, objectId: 'KA 05 MZ 0231', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car42024-09-11.jpeg', timeIn: '10:31 AM', timeOut: '01:27 PM', timeSpent: '2h 56m' },
{ id: 5, objectId: 'KA 09 MG 6404', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car52024-09-11.jpeg', timeIn: '11:12 AM', timeOut: '01:53 PM', timeSpent: '2h 41m' },
{ id: 6, objectId: 'KA 51 MN 8457', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car62024-09-11.jpeg', timeIn: '11:54 AM', timeOut: '02:42 PM', timeSpent: '2h 48m' },
{ id: 7, objectId: 'KA 09 ME 9815', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car72024-09-11.jpeg', timeIn: '12:01 PM', timeOut: '01:41 PM', timeSpent: '1h 40m' },
{ id: 8, objectId: 'KA 09 MH 7259', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car82024-09-11.jpeg', timeIn: '01:02 PM', timeOut: '02:49 PM', timeSpent: '1h 47m' },
{ id: 9, objectId: 'KA 09 MK 1632', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car92024-09-11.jpeg', timeIn: '01:33 PM', timeOut: '06:20 PM', timeSpent: '4h 47m' },
{ id: 10, objectId: 'KA 09 MK 0699', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car102024-09-11.jpeg', timeIn: '01:39 PM', timeOut: '05:55 PM', timeSpent: '4h 16m' },
{ id: 11, objectId: 'KA 09 MH 9496', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car112024-09-11.jpeg', timeIn: '02:02 PM', timeOut: '03:31 PM', timeSpent: '1h 29m' },
{ id: 12, objectId: 'KA 09 ME 8705', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car122024-09-11.jpeg', timeIn: '03:17 PM', timeOut: '04:40 PM', timeSpent: '1h 23m' },
{ id: 13, objectId: 'KA 50 P 3757', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car132024-09-11.jpeg', timeIn: '03:38 PM', timeOut: '05:35 PM', timeSpent: '1h 57m' },
{ id: 14, objectId: 'KA 53 Z 6731', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car142024-09-11.jpeg', timeIn: '04:38 PM', timeOut: '06:36 PM', timeSpent: '1h 58m' },
{ id: 15, objectId: 'KA 09 MK 3145', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car152024-09-11.jpeg', timeIn: '04:40 PM', timeOut: '07:05 PM', timeSpent: '2h 25m' },
{ id: 16, objectId: 'KA 09 MF 0330', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car162024-09-11.jpeg', timeIn: '05:14 PM', timeOut: '07:35 PM', timeSpent: '2h 21m' },
{ id: 17, objectId: 'KA 09 MA 1023', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car172024-09-11.jpeg', timeIn: '06:01 PM', timeOut: '08:02 PM', timeSpent: '2h 1m' },
{ id: 18, objectId: 'KA 42 N 1213', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car182024-09-11.jpeg', timeIn: '07:14 PM', timeOut: '08:30 PM', timeSpent: '1h 16m' },
{ id: 19, objectId: 'KA 05 NL 1875', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car192024-09-11.jpeg', timeIn: '08:15 PM', timeOut: '09:13 PM', timeSpent: '58m' }
];

      }
        
      else if (formattedDate === '12/09/2024') {
        return [
                  
{ id: 1, objectId: 'KA 55 N 3030', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car12024-09-12.jpeg', timeIn: '08:52 AM', timeOut: '09:34 AM', timeSpent: '42 minutes' },
{ id: 2, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car22024-09-12.jpeg', timeIn: '09:55 AM', timeOut: '11:11 AM', timeSpent: '1 hour 16 minutes' },
{ id: 3, objectId: 'KA 05 MU 3286', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car32024-09-12.jpeg', timeIn: '10:08 AM', timeOut: '11:11 AM', timeSpent: '1 hour 3 minutes' },
{ id: 4, objectId: 'KA 09 MG 4412', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car42024-09-12.jpeg', timeIn: '10:21 AM', timeOut: '11:47 AM', timeSpent: '1 hour 26 minutes' },
{ id: 5, objectId: 'KA 12 MB 6038', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car52024-09-12.jpeg', timeIn: '10:27 AM', timeOut: '12:07 PM', timeSpent: '1 hour 40 minutes' },
{ id: 6, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car62024-09-12.jpeg', timeIn: '', timeOut: '', timeSpent: '' }, // Missing timeIn and timeOut
{ id: 7, objectId: 'KA 05 MF 8544', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car72024-09-12.jpeg', timeIn: '11:10 AM', timeOut: '12:54 PM', timeSpent: '1 hour 44 minutes' },
{ id: 8, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car82024-09-12.jpeg', timeIn: '', timeOut: '', timeSpent: '' }, // Missing timeIn and timeOut
{ id: 9, objectId: 'KA 09 MH 8968', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car92024-09-12.jpeg', timeIn: '11:38 AM', timeOut: '01:25 PM', timeSpent: '1 hour 47 minutes' },
{ id: 10, objectId: 'KA 05 MN 6788', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car102024-09-12.jpeg', timeIn: '12:31 PM', timeOut: '01:36 PM', timeSpent: '1 hour 5 minutes' },
{ id: 11, objectId: 'KA 09 MB 2205', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car112024-09-12.jpeg', timeIn: '01:47 PM', timeOut: '02:03 PM', timeSpent: '16 minutes' },
{ id: 12, objectId: 'KA 09 MG 7107', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car122024-09-12.jpeg', timeIn: '03:21 PM', timeOut: '04:15 PM', timeSpent: '54 minutes' },
{ id: 13, objectId: 'KA 55 N 1600', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car132024-09-12.jpeg', timeIn: '03:33 PM', timeOut: '04:37 PM', timeSpent: '1 hour 4 minutes' },
{ id: 14, objectId: 'KA 01 MN 5493', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car142024-09-12.jpeg', timeIn: '04:40 PM', timeOut: '05:40 PM', timeSpent: '1 hour' },
{ id: 15, objectId: 'TN 66 F 3458', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car152024-09-12.jpeg', timeIn: '05:28 PM', timeOut: '06:55 PM', timeSpent: '1 hour 27 minutes' },
{ id: 16, objectId: 'KA 55 N 4446', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car162024-09-12.jpeg', timeIn: '05:37 PM', timeOut: '06:56 PM', timeSpent: '1 hour 19 minutes' },
{ id: 17, objectId: 'KA 09 MB 7994', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car172024-09-12.jpeg', timeIn: '05:50 PM', timeOut: '07:29 PM', timeSpent: '1 hour 39 minutes' },
{ id: 18, objectId: 'KA 55 M 1213', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car182024-09-12.jpeg', timeIn: '06:42 PM', timeOut: '07:37 PM', timeSpent: '55 minutes' },
{ id: 19, objectId: 'KA 05 ME 4245', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car192024-09-12.jpeg', timeIn: '07:48 PM', timeOut: '08:29 PM', timeSpent: '41 minutes' }


];

    }

else if (formattedDate === '13/09/2024') {
        return [

{ id: 1, objectId: 'KA 09 MG 7677', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car12024-09-13.jpeg', timeIn: '08:55 AM', timeOut: '09:54 AM', timeSpent: '00:59' },
{ id: 2, objectId: 'KA 41 M 7028', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car22024-09-13.jpeg', timeIn: '09:23 AM', timeOut: '10:22 AM', timeSpent: '00:59' },
{ id: 3, objectId: 'KA 09 MJ 0913', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car32024-09-13.jpeg', timeIn: '09:46 AM', timeOut: '12:46 PM', timeSpent: '03:00' },
{ id: 4, objectId: 'KA 09 MH 2914', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car42024-09-13.jpeg', timeIn: '10:15 AM', timeOut: '11:41 AM', timeSpent: '01:26' },
{ id: 5, objectId: 'KA 03 MC 1902', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car52024-09-13.jpeg', timeIn: '10:28 AM', timeOut: '11:56 AM', timeSpent: '01:28' },
{ id: 6, objectId: 'KA 05 NH 2255', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car62024-09-13.jpeg', timeIn: '10:32 AM', timeOut: '12:29 PM', timeSpent: '01:57' },
{ id: 7, objectId: 'KA 09 MD 8816', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car72024-09-13.jpeg', timeIn: '12:02 PM', timeOut: '01:21 PM', timeSpent: '01:19' },
{ id: 8, objectId: 'KA 03 NB 8238', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car82024-09-13.jpeg', timeIn: '12:20 PM', timeOut: '01:55 PM', timeSpent: '01:35' },
{ id: 9, objectId: 'KA 50 MA 0111', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car92024-09-13.jpeg', timeIn: '12:34 PM', timeOut: '02:27 PM', timeSpent: '01:53' },
{ id: 10, objectId: 'KA 10 M 4738', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car102024-09-13.jpeg', timeIn: '12:51 PM', timeOut: '02:20 PM', timeSpent: '01:29' },
{ id: 11, objectId: 'KA 18 P 2500', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car112024-09-13.jpeg', timeIn: '01:02 PM', timeOut: '02:41 PM', timeSpent: '01:39' },
{ id: 12, objectId: 'KA 09 MG 1248', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car122024-09-13.jpeg', timeIn: '01:39 PM', timeOut: '03:11 PM', timeSpent: '01:32' },
{ id: 13, objectId: 'KA 09 Z 3428', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car132024-09-13.jpeg', timeIn: '02:15 PM', timeOut: '03:27 PM', timeSpent: '01:12' },
{ id: 14, objectId: 'KA 09 MF 9177', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car142024-09-13.jpeg', timeIn: '02:37 PM', timeOut: '03:55 PM', timeSpent: '01:18' },
{ id: 15, objectId: 'KA 05 MZ 6432', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car152024-09-13.jpeg', timeIn: '03:14 PM', timeOut: '04:22 PM', timeSpent: '01:08' },
{ id: 16, objectId: 'KA 09 MJ 4782', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car162024-09-13.jpeg', timeIn: '03:28 PM', timeOut: '05:06 PM', timeSpent: '01:38' },
{ id: 17, objectId: 'CH 01 BU 6437', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car172024-09-13.jpeg', timeIn: '03:38 PM', timeOut: '04:10 PM', timeSpent: '00:32' },
{ id: 18, objectId: 'EXOTIC', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car182024-09-13.jpeg', timeIn: '04:27 PM', timeOut: '05:13 PM', timeSpent: '00:46' },
{ id: 19, objectId: 'KA 01 MQ 4756', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car192024-09-13.jpeg', timeIn: '04:56 PM', timeOut: '07:08 PM', timeSpent: '02:12' },
{ id: 20, objectId: 'KA 09 4203', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car202024-09-13.jpeg', timeIn: '05:16 PM', timeOut: '06:08 PM', timeSpent: '00:52' },
{ id: 21, objectId: 'KA 09 MF 0155', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car212024-09-13.jpeg', timeIn: '', timeOut: '', timeSpent: '' }, // No data
{ id: 22, objectId: 'KA 12 MB 8524', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car222024-09-13.jpeg', timeIn: '03:34 PM', timeOut: '04:25 PM', timeSpent: '00:51' },
{ id: 23, objectId: 'KA 09 MA 0264', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car232024-09-13.jpeg', timeIn: '05:28 PM', timeOut: '07:03 PM', timeSpent: '01:35' },
{ id: 24, objectId: 'KA 04 MS 6090', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car242024-09-13.jpeg', timeIn: '06:03 PM', timeOut: '07:45 PM', timeSpent: '01:42' },
{ id: 25, objectId: 'TN 06 R 4615', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car252024-09-13.jpeg', timeIn: '06:10 PM', timeOut: '07:41 PM', timeSpent: '01:31' },
{ id: 26, objectId: 'KA 09 MJ 0184', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car262024-09-13.jpeg', timeIn: '07:00 PM', timeOut: '08:41 PM', timeSpent: '01:41' },
{ id: 27, objectId: 'KA 09 MG 3943', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car272024-09-13.jpeg', timeIn: '07:23 PM', timeOut: '08:15 PM', timeSpent: '00:52' },
{ id: 28, objectId: 'KA 09 MH 2363', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car282024-09-13.jpeg', timeIn: '07:27 PM', timeOut: '08:51 PM', timeSpent: '01:24' },
{ id: 29, objectId: 'KA 09 MH 4765', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car292024-09-13.jpeg', timeIn: '06:48 PM', timeOut: '09:15 PM', timeSpent: '02:27' },
{ id: 30, objectId: 'KA 41 P 0772', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car302024-09-13.jpeg', timeIn: '06:53 PM', timeOut: '08:01 PM', timeSpent: '01:08' },
{ id: 31, objectId: 'KA 04 MJ 1091', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car312024-09-13.jpeg', timeIn: '09:19 PM', timeOut: '', timeSpent: '' },


];

    } 
    else if (formattedDate === '14/09/2024') {
      return [
        { id: 1, objectId: 'KA 03 ME 3935', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car12024-09-14.jpeg', timeIn: '09:00 AM', timeOut: '10:15 AM', timeSpent: '01:15' },
        { id: 2, objectId: 'kA 09 MJ 5455', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car22024-09-14.jpeg', timeIn: '09:14 AM', timeOut: '10:54 AM', timeSpent: '01:40' },
        { id: 3, objectId: 'KL 57 N 7759', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car32024-09-14.jpeg', timeIn: '09:38 AM', timeOut: '10:32 AM', timeSpent: '00:54' },
        { id: 4, objectId: 'KA 09 MC 4177', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car42024-09-14.jpeg', timeIn: '09:50 AM', timeOut: '11:18 AM', timeSpent: '01:28' },
        { id: 5, objectId: 'KA 09 MD 1366', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car52024-09-14.jpeg', timeIn: '10:17 AM', timeOut: '02:10 PM', timeSpent: '03:53' },
        { id: 6, objectId: 'KA 55 N 0982', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car62024-09-14.jpeg', timeIn: '11:00 AM', timeOut: '11:55 AM', timeSpent: '00:55' },
        { id: 7, objectId: 'KA 09 MF 8573', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car72024-09-14.jpeg', timeIn: '11:08 AM', timeOut: '12:27 PM', timeSpent: '01:19' },
        { id: 8, objectId: 'KA 09 MK 0570', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car82024-09-14.jpeg', timeIn: '11:24 AM', timeOut: '12:56 PM', timeSpent: '01:32' },
        { id: 9, objectId: 'KA 02 MP 6563', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car92024-09-14.jpeg', timeIn: '11:46 AM', timeOut: '01:11 PM', timeSpent: '01:25' },
        { id: 10, objectId: 'KA 51 MH 5027', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car102024-09-14.jpeg', timeIn: '12:08 PM', timeOut: '01:33 PM', timeSpent: '01:25' },
        { id: 11, objectId: 'KA 10 M 2919', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car112024-09-14.jpeg', timeIn: '12:22 PM', timeOut: '01:41 PM', timeSpent: '01:19' },
        { id: 12, objectId: 'KA 09 MJ 3993', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car122024-09-14.jpeg', timeIn: '12:29 PM', timeOut: '03:54 PM', timeSpent: '03:25' },
        { id: 13, objectId: 'EXOTIC', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car132024-09-14.jpeg', timeIn: '01:14 PM', timeOut: '04:42 PM', timeSpent: '03:28' },
        { id: 14, objectId: 'KA 09 Z 9975', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car142024-09-14.jpeg', timeIn: '03:09 PM', timeOut: '04:39 PM', timeSpent: '01:30' },
        { id: 15, objectId: 'KA 53 MH 2939', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car152024-09-14.jpeg', timeIn: '03:45 PM', timeOut: '05:03 PM', timeSpent: '01:18' },
        { id: 16, objectId: 'KA 09 MK 2738', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car162024-09-14.jpeg', timeIn: '03:58 PM', timeOut: '05:36 PM', timeSpent: '01:38' },
        { id: 17, objectId: 'KA 09 MJ 1525', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car172024-09-14.jpeg', timeIn: '04:26 PM', timeOut: '05:50 PM', timeSpent: '01:24' },
        { id: 18, objectId: 'KA 55 N 0664', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car182024-09-14.jpeg', timeIn: '05:38 PM', timeOut: '06:34 PM', timeSpent: '00:56' },
        { id: 19, objectId: 'KA 09 MG 1278', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car192024-09-14.jpeg', timeIn: '05:56 PM', timeOut: '07:25 PM', timeSpent: '01:29' },
        { id: 20, objectId: 'KA 09 MK 0886', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car202024-09-14.jpeg', timeIn: '06:18 PM', timeOut: '07:54 PM', timeSpent: '01:36' },
        { id: 21, objectId: 'KA 09 MD 9933', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car212024-09-14.jpeg', timeIn: '06:58 PM', timeOut: '08:47 PM', timeSpent: '01:49' },
        { id: 22, objectId: 'KA 09 MD 8452', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car222024-09-14.jpeg', timeIn: '07:28 PM', timeOut: '08:52 PM', timeSpent: '01:24' },
        { id: 23, objectId: 'CH 01 BU 6437', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car232024-09-14.jpeg', timeIn: '04:29 PM', timeOut: '', timeSpent: '' }
      ];
      

  }           
else if (formattedDate === '15/09/2024') {
      return [


{ id: 1, objectId: 'RJ 14 CM 8414', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car12024-09-15.jpeg', timeIn: '09:13 AM', timeOut: '10:10 AM', timeSpent: '00:57' },
{ id: 2, objectId: 'KA 09 MB 2356', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car22024-09-15.jpeg', timeIn: '09:38 AM', timeOut: '10:44 AM', timeSpent: '01:06' },
{ id: 3, objectId: 'KA 10 M 8479', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car32024-09-15.jpeg', timeIn: '09:54 AM', timeOut: '11:11 AM', timeSpent: '01:17' },
{ id: 4, objectId: 'KA 09 NC 9045', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car42024-09-15.jpeg', timeIn: '09:42 AM', timeOut: '10:44 AM', timeSpent: '01:02' },
{ id: 5, objectId: 'KA 03 NG 6332', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car52024-09-15.jpeg', timeIn: '10:09 AM', timeOut: '11:36 AM', timeSpent: '01:27' },
{ id: 6, objectId: 'KA 09 MA 8433', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car62024-09-15.jpeg', timeIn: '10:26 AM', timeOut: '11:44 AM', timeSpent: '01:18' },
{ id: 7, objectId: 'KA 08 MC 1772', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car72024-09-15.jpeg', timeIn: '10:28 AM', timeOut: '02:13 PM', timeSpent: '03:45' },
{ id: 8, objectId: 'KA 09 ME 3138', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car82024-09-15.jpeg', timeIn: '11:04 AM', timeOut: '12:37 PM', timeSpent: '01:33' },
{ id: 9, objectId: 'KA 09 MB 5801', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car92024-09-15.jpeg', timeIn: '12:04 PM', timeOut: '01:17 PM', timeSpent: '01:13' },
{ id: 22, objectId: 'KA 09 MH 7733', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/CAR222024-09-15.jpeg', timeIn: '12:19 PM', timeOut: '01:34 PM', timeSpent: '01:15' },
{ id: 10, objectId: 'KA 09 MH 3778', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car102024-09-15.jpeg', timeIn: '01:00 PM', timeOut: '02:24 PM', timeSpent: '01:24' },
{ id: 11, objectId: 'KA 09 MG 0410', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car112024-09-15.jpeg', timeIn: '01:09 PM', timeOut: '02:39 PM', timeSpent: '01:30' },
{ id: 12, objectId: 'PB 65 AN 1856', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car122024-09-15.jpeg', timeIn: '01:17 PM', timeOut: '02:22 PM', timeSpent: '01:05' },
{ id: 13, objectId: 'KA 09 ME 2759', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car132024-09-15.jpeg', timeIn: '01:58 PM', timeOut: '02:50 PM', timeSpent: '00:52' },
{ id: 14, objectId: 'KA 09 MJ 9991', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car142024-09-15.jpeg', timeIn: '03:17 PM', timeOut: '04:33 PM', timeSpent: '01:16' },
{ id: 15, objectId: 'KA 09 ML 5940', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car152024-09-15.jpeg', timeIn: '03:53 PM', timeOut: '07:14 PM', timeSpent: '03:21' },
{ id: 16, objectId: 'KA 09 MF 6349', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car162024-09-15.jpeg', timeIn: '06:14 PM', timeOut: '07:45 PM', timeSpent: '01:31' },
{ id: 17, objectId: 'KA 09 MK 1542', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car172024-09-15.jpeg', timeIn: '06:25 PM', timeOut: '07:38 PM', timeSpent: '01:13' },
{ id: 18, objectId: 'KA 09 MF 7866', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car182024-09-15.jpeg', timeIn: '06:43 PM', timeOut: '07:25 PM', timeSpent: '00:42' },
{ id: 19, objectId: 'KA 09 MD 7905', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car192024-09-15.jpeg', timeIn: '07:00 PM', timeOut: '07:58 PM', timeSpent: '00:58' },
{ id: 20, objectId: 'KA 09 MS 7857', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car202024-09-15.jpeg', timeIn: '07:19 PM', timeOut: '08:37 PM', timeSpent: '01:18' },
{ id: 21, objectId: 'KA 09 MH 4125', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car212024-09-15.jpeg', timeIn: '07:45 PM', timeOut: '08:43 PM', timeSpent: '00:58' }



];

  }           			
  else if (formattedDate === '16/09/2024') {
    return [



{ id: 1, objectId: 'KA 51 MO 9851', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car12024-09-16.jpeg', timeIn: '08:00 AM', timeOut: '10:15 AM', timeSpent: '2:15' },
{ id: 2, objectId: 'KA 04 MX 1060', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car22024-09-16.jpeg', timeIn: '09:50 AM', timeOut: '11:30 AM', timeSpent: '1:40' },
{ id: 3, objectId: 'KA 09 MK 2738', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car32024-09-16.jpeg', timeIn: '10:06 AM', timeOut: '10:54 AM', timeSpent: '0:48' },
{ id: 4, objectId: 'KA 09 P 2439', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car42024-09-16.jpeg', timeIn: '10:34 AM', timeOut: '12:29 PM', timeSpent: '1:55' },
{ id: 5, objectId: 'TN 07 AE 6351', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car52024-09-16.jpeg', timeIn: '10:41 AM', timeOut: '02:18 PM', timeSpent: '3:37' },
{ id: 6, objectId: 'KA 09 MD 0419', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car62024-09-16.jpeg', timeIn: '11:17 AM', timeOut: '01:30 PM', timeSpent: '2:13' },
{ id: 7, objectId: 'KA 09 MG 9657', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car72024-09-16.jpeg', timeIn: '12:00 PM', timeOut: '01:31 PM', timeSpent: '1:31' },
{ id: 8, objectId: 'KA 09 MF 2760', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car82024-09-16.jpeg', timeIn: '12:18 PM', timeOut: '01:35 PM', timeSpent: '1:17' },
{ id: 9, objectId: 'KA 50 MB 4699', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car92024-09-16.jpeg', timeIn: '02:02 PM', timeOut: '03:22 PM', timeSpent: '1:20' },
{ id: 10, objectId: 'KA 09 M0 7133', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car102024-09-16.jpeg', timeIn: '02:14 PM', timeOut: '03:56 PM', timeSpent: '1:42' },
{ id: 11, objectId: 'KA 03 MN 4232', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car112024-09-16.jpeg', timeIn: '02:20 PM', timeOut: '04:13 PM', timeSpent: '1:53' },
{ id: 12, objectId: 'KA 09 AA 3291', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car122024-09-16.jpeg', timeIn: '03:12 PM', timeOut: '04:46 PM', timeSpent: '1:34' },
{ id: 13, objectId: 'KA 09 MC 2100', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car132024-09-16.jpeg', timeIn: '03:35 PM', timeOut: '05:34 PM', timeSpent: '1:59' },
{ id: 14, objectId: 'KA 09 MG 0711', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car142024-09-16.jpeg', timeIn: '05:00 PM', timeOut: '05:48 PM', timeSpent: '0:48' },
{ id: 15, objectId: 'KA 09 MC 5719', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car152024-09-16.jpeg', timeIn: '05:31 PM', timeOut: '07:01 PM', timeSpent: '1:30' },
{ id: 16, objectId: 'KA 13 P 8248', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car162024-09-16.jpeg', timeIn: '05:56 PM', timeOut: '07:35 PM', timeSpent: '1:39' },
{ id: 17, objectId: 'KA 09 ME 6331', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car172024-09-16.jpeg', timeIn: '06:31 PM', timeOut: '07:15 PM', timeSpent: '0:44' },
{ id: 18, objectId: 'KA 09 MD 2115', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car182024-09-16.jpeg', timeIn: '07:07 PM', timeOut: '08:28 PM', timeSpent: '1:21' },
{ id: 19, objectId: 'KA 09 MO 0419', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car192024-09-16.jpeg', timeIn: '07:36 PM', timeOut: '08:37 PM', timeSpent: '1:01' }



];

}  			
else if (formattedDate === '17/09/2024') {
    return [
{ id: 1, objectId: 'KA 01 MP 4401', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car12024-09-17.jpeg', timeIn: '09:07 PM', timeOut: '10:01 PM', timeSpent: '00:54' },
{ id: 2, objectId: 'KA 42 N 7695', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car22024-09-17.jpeg', timeIn: '10:34 AM', timeOut: '12:04 PM', timeSpent: '01:30' },
{ id: 3, objectId: 'KA 09 MH 0414', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car32024-09-17.jpeg', timeIn: '10:40 AM', timeOut: '11:53 AM', timeSpent: '01:13' },
{ id: 4, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car42024-09-17.jpeg', timeIn: '', timeOut: '', timeSpent: '' },
{ id: 5, objectId: 'KA 01 MS 8381', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car52024-09-17.jpeg', timeIn: '11:16 AM', timeOut: '01:11 PM', timeSpent: '01:55' },
{ id: 6, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car62024-09-17.jpeg', timeIn: '11:36 AM', timeOut: '12:33 PM', timeSpent: '00:57' },
{ id: 7, objectId: 'KA 09 MG 1642', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car72024-09-17.jpeg', timeIn: '11:43 AM', timeOut: '01:35 PM', timeSpent: '01:52' },
{ id: 8, objectId: 'JH 01 EZ 2466', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car82024-09-17.jpeg', timeIn: '02:24 PM', timeOut: '01:55 PM', timeSpent: '00:00' },
{ id: 9, objectId: 'KA 04 P 6165', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car92024-09-17.jpeg', timeIn: '12:27 PM', timeOut: '01:58 PM', timeSpent: '01:31' },
{ id: 10, objectId: 'HR 26 CB 6462', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car102024-09-17.jpeg', timeIn: '01:06 PM', timeOut: '02:53 PM', timeSpent: '01:47' },
{ id: 11, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car112024-09-17.jpeg', timeIn: '01:11 PM', timeOut: '02:45 PM', timeSpent: '01:34' },
{ id: 12, objectId: 'KA 09 MF 6080', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car122024-09-17.jpeg', timeIn: '01:36 PM', timeOut: '03:33 PM', timeSpent: '01:57' },
{ id: 13, objectId: 'KA 51 MG 3042', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car13024-09-17.jpeg', timeIn: '01:29 PM', timeOut: '03:26 PM', timeSpent: '01:57' },
{ id: 14, objectId: 'UP 78 EK 5514', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car142024-09-17.jpeg', timeIn: '03:35 PM', timeOut: '04:15 PM', timeSpent: '00:40' },
{ id: 15, objectId: 'KA 09 MK 2809', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car152024-09-17.jpeg', timeIn: '04:27 PM', timeOut: '05:28 PM', timeSpent: '01:01' },
{ id: 16, objectId: 'KA 09 MF 1723', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car162024-09-17.jpeg', timeIn: '05:11 PM', timeOut: '06:14 PM', timeSpent: '01:03' },
{ id: 17, objectId: 'KA 50 MB 7788', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car172024-09-17.jpeg', timeIn: '05:32 PM', timeOut: '06:54 PM', timeSpent: '01:22' },
{ id: 18, objectId: 'KA 05 P 3239', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car182024-09-17.jpeg', timeIn: '07:05 PM', timeOut: '08:29 PM', timeSpent: '01:24' },
{ id: 19, objectId: 'KA 09 MD 1993', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car192024-09-17.jpeg', timeIn: '07:35 PM', timeOut: '08:21 PM', timeSpent: '00:46' },
{ id: 20, objectId: 'KA 09 P 6392', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car20024-09-17.jpeg', timeIn: '07:50 PM', timeOut: '08:46 PM', timeSpent: '00:56' },
{ id: 21, objectId: 'KA 09 MJ 7610', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car212024-09-17.jpeg', timeIn: '08:19 PM', timeOut: '09:36 PM', timeSpent: '01:17' }
];


}  		
else if (formattedDate === '18/09/2024') {
  return [
{ id: 1, objectId: 'MH 12 UF 9041', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car12024-09-18.jpeg', timeIn: '09:17 AM', timeOut: '09:44 AM', timeSpent: '00:27' },
{ id: 2, objectId: 'KA 09 MD 5775', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car22024-09-18.jpeg', timeIn: '09:40 AM', timeOut: '10:24 AM', timeSpent: '00:44' },
{ id: 3, objectId: 'KA 09 MF 4495', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car32024-09-18.jpeg', timeIn: '10:28 AM', timeOut: '11:50 AM', timeSpent: '01:22' },
{ id: 4, objectId: 'KA 09 MK 3748', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car42024-09-18.jpeg', timeIn: '10:43 AM', timeOut: '11:29 AM', timeSpent: '00:46' },
{ id: 5, objectId: 'KA 11 P 9996', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car52024-09-18.jpeg', timeIn: '11:10 AM', timeOut: '12:53 PM', timeSpent: '01:43' },
{ id: 6, objectId: 'KA 09 MK 0819', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car62024-09-18.jpeg', timeIn: '11:46 AM', timeOut: '12:43 PM', timeSpent: '00:57' },
{ id: 7, objectId: 'KA 53 MO 5013', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car72024-09-18.jpeg', timeIn: '12:16 PM', timeOut: '12:59 PM', timeSpent: '00:43' },
{ id: 8, objectId: 'KA 09 MK 1470', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car82024-09-18.jpeg', timeIn: '12:36 PM', timeOut: '02:00 PM', timeSpent: '01:24' },
{ id: 9, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car92024-09-18.jpeg', timeIn: '12:48 PM', timeOut: '04:27 PM', timeSpent: '03:39' },
{ id: 10, objectId: 'KA 05 NE 5326', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car102024-09-18.jpeg', timeIn: '03:00 PM', timeOut: '05:05 PM', timeSpent: '02:05' },
{ id: 11, objectId: 'KA 09 MJ 6199', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car112024-09-18.jpeg', timeIn: '04:15 PM', timeOut: '04:41 PM', timeSpent: '00:26' },
{ id: 12, objectId: 'KA 09 MB 7895', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car122024-09-18.jpeg', timeIn: '04:42 PM', timeOut: '06:38 PM', timeSpent: '01:56' },
{ id: 13, objectId: 'KA 09 MK 6116', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car132024-09-18.jpeg', timeIn: '04:20 PM', timeOut: '05:22 PM', timeSpent: '01:02' },
{ id: 14, objectId: 'GJ 36 AJ 1239', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car142024-09-18.jpeg', timeIn: '04:40 PM', timeOut: '06:01 PM', timeSpent: '01:21' },
{ id: 15, objectId: 'KA 09 MJ 1533', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car152024-09-18.jpeg', timeIn: '05:07 PM', timeOut: '06:41 PM', timeSpent: '01:34' },
{ id: 16, objectId: 'KA 09 MA 1023', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car162024-09-18.jpeg', timeIn: '05:24 PM', timeOut: '07:06 PM', timeSpent: '01:42' },
{ id: 17, objectId: 'KA 17 MA 5797', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car172024-09-18.jpeg', timeIn: '06:02 PM', timeOut: '08:11 PM', timeSpent: '02:09' },
{ id: 18, objectId: 'KA 03 NR 6345', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car182024-09-18.jpeg', timeIn: '06:19 PM', timeOut: '07:26 PM', timeSpent: '01:07' },
{ id: 19, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car192024-09-18.jpeg', timeIn: '06:28 PM', timeOut: '07:56 PM', timeSpent: '01:28' },
{ id: 20, objectId: '23 BH 94250', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car202024-09-18.jpeg', timeIn: '07:15 PM', timeOut: '08:30 PM', timeSpent: '01:15' },
{ id: 21, objectId: 'PY 05 VD 0803', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car222024-09-18.jpeg', timeIn: '07:35 PM', timeOut: '09:07 PM', timeSpent: '01:32' }
];



}  

else if (formattedDate === '19/09/2024') {
  return [
{ id: 1, objectId: 'KA 09 MK 3398', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car12024-09-19.jpeg', timeIn: '10:02 AM', timeOut: '10:34 AM', timeSpent: '00:32' },
{ id: 2, objectId: 'KA 09 MK 0699', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car22024-09-19.jpeg', timeIn: '10:30 AM', timeOut: '11:24 AM', timeSpent: '00:54' },
{ id: 3, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car32024-09-19.jpeg', timeIn: '10:29 AM', timeOut: '11:49 AM', timeSpent: '01:20' },
{ id: 4, objectId: 'KA 02 MN 8486', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car42024-09-19.jpeg', timeIn: '11:43 AM', timeOut: '12:03 PM', timeSpent: '01:46'  },
{ id: 5, objectId: 'KA 05 NE 5326', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car52024-09-19.jpeg', timeIn: '11:26 AM', timeOut: '12:16 AM', timeSpent: '12:50' },
{ id: 6, objectId: 'KA 01 MK 4372', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car62024-09-19.jpeg', timeIn: '12:03 PM', timeOut: '01:39 PM', timeSpent: '01:36' },
{ id: 7, objectId: 'KA 05 MX 1053', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car72024-09-19.jpeg', timeIn: '12:18 PM', timeOut: '02:37 PM', timeSpent: '02:19' },
{ id: 8, objectId: 'KA 09 ME 3509', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car82024-09-19.jpeg', timeIn: '12:35 PM', timeOut: '02:36 PM', timeSpent: '02:01' },
{ id: 9, objectId: 'KA 02 MG 6965', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car92024-09-19.jpeg', timeIn: '01:10 PM', timeOut: '02:16 PM', timeSpent: '01:06' },
{ id: 10, objectId: 'MH 12 TY 4959', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car102024-09-19.jpeg', timeIn: '01:34 PM', timeOut: '02:04 PM', timeSpent: '00:30' },
{ id: 11, objectId: 'KA 12 MA 7517', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car112024-09-19.jpeg', timeIn: '02:29 PM', timeOut: '04:06 PM', timeSpent: '01:37' },
{ id: 12, objectId: 'KA 03 ND 8044', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car122024-09-19.jpeg', timeIn: '02:46 PM', timeOut: '06:18 PM', timeSpent: '03:32' },
{ id: 13, objectId: 'KA 09 MB 7041', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car132024-09-19.jpeg', timeIn: '03:25 PM', timeOut: '04:38 PM', timeSpent: '01:13' },
{ id: 14, objectId: 'KA 09 MA 5885', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car142024-09-19.jpeg', timeIn: '04:08 PM', timeOut: '06:59 PM', timeSpent: '02:51' },
{ id: 15, objectId: 'KA 09 MD 6525', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car152024-09-19.jpeg', timeIn: '04:31 PM', timeOut: '05:19 PM', timeSpent: '00:48' },
{ id: 16, objectId: 'KA 09 MJ 4933', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car162024-09-19.jpeg', timeIn: '05:05 PM', timeOut: '06:00 PM', timeSpent: '00:55' },
{ id: 17, objectId: 'KA 13 P 1869', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car172024-09-19.jpeg', timeIn: '05:36 PM', timeOut: '06:22 PM', timeSpent: '00:46' },
{ id: 18, objectId: 'KA 09 MK 3145', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car182024-09-19.jpeg', timeIn: '05:38 PM', timeOut: '07:39 PM', timeSpent: '02:01' },
{ id: 19, objectId: 'KA 03 MS 1899', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car192024-09-19.jpeg', timeIn: '06:10 PM', timeOut: '06:59 PM', timeSpent: '00:49' },
{ id: 20, objectId: 'KA 55 N 2651', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car202024-09-19.jpeg', timeIn: '06:07 PM', timeOut: '07:12 PM', timeSpent: '01:05' },
{ id: 21, objectId: 'KA 09 MJ 2932', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car212024-09-19.jpeg', timeIn: '07:49 PM', timeOut: '09:05 PM', timeSpent: '01:16' },
{ id: 22, objectId: 'KA 02 MG 6965', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car222024-09-19.jpeg', timeIn: '09:16 PM', timeOut: '', timeSpent: '' },
{ id: 23, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car232024-09-19.jpeg', timeIn: '', timeOut: '09:13 ', timeSpent: '' }
];



}  					
else if (formattedDate === '20/09/2024') {
  return [
{ id: 1, objectId: 'KA 09 MF 7727', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car12024-09-20.jpeg', timeIn: '10:00 AM', timeOut: '12:13 PM', timeSpent: '02:13' },
{ id: 2, objectId: 'KA 09 MH 8485', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car22024-09-20.jpeg', timeIn: '10:09 AM', timeOut: '11:27 AM', timeSpent: '01:18' },
{ id: 3, objectId: 'KA 05 NO 1317', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car32024-09-20.jpeg', timeIn: '10:43 AM', timeOut: '11:46 AM', timeSpent: '01:03' },
{ id: 4, objectId: '24 BH 15360', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car42024-09-20.jpeg', timeIn: '11:07 AM', timeOut: '12:28 PM', timeSpent: '01:21' },
{ id: 5, objectId: 'KA 03 MS 0562', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car52024-09-20.jpeg', timeIn: '11:22 AM', timeOut: '12:34 PM', timeSpent: '01:12' },
{ id: 6, objectId: 'KA 09 MA 7994', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car62024-09-20.jpeg', timeIn: '11:45 AM', timeOut: '07:35 PM', timeSpent: '07:50' },
{ id: 7, objectId: 'UP 78 EK 5514', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car72024-09-20.jpeg', timeIn: '12:16 PM', timeOut: '02:50 PM', timeSpent: '02:34' },
{ id: 8, objectId: 'KA 09 N 3653', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car82024-09-20.jpeg', timeIn: '12:51 PM', timeOut: '04:08 PM', timeSpent: '03:17' },
{ id: 9, objectId: 'KL 04 V 1843', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car92024-09-20.jpeg', timeIn: '01:47 PM', timeOut: '02:29 PM', timeSpent: '00:42' },
{ id: 10, objectId: 'KA 03 M2 4599', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car102024-09-20.jpeg', timeIn: '02:10 PM', timeOut: '04:01 PM', timeSpent: '01:51' },
{ id: 11, objectId: 'KA 53 MC 9695 ', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car112024-09-20.jpeg', timeIn: '02:38 PM', timeOut: '04:05 PM', timeSpent: '01:27' },
{ id: 12, objectId: 'KA 09 ME 3247', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car122024-09-20.jpeg', timeIn: '03:10 PM', timeOut: '03:14 PM', timeSpent: '00:04' },
{ id: 13, objectId: 'KA 55 M 1848', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car132024-09-20.jpeg', timeIn: '04:35 PM', timeOut: '05:30 PM', timeSpent: '00:55' },
{ id: 14, objectId: 'KA 31 N 5422', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car142024-09-20.jpeg', timeIn: '07:00 PM', timeOut: '07:33 PM', timeSpent: '00:33' },
{ id: 15, objectId: 'KA 01 PN 5493', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car152024-09-20.jpeg', timeIn: '05:43 PM', timeOut: '06:43 PM', timeSpent: '01:00' },
{ id: 16, objectId: 'KA 09 ME 4350', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car162024-09-20.jpeg', timeIn: '05:33 PM', timeOut: '06:30 PM', timeSpent: '00:57' },
{ id: 17, objectId: 'KA 55 N 1101', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car172024-09-20.jpeg', timeIn: '06:33 PM', timeOut: '07:33 PM', timeSpent: '01:00' },
{ id: 18, objectId: 'KA 02 MG 5440', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car182024-09-20.jpeg', timeIn: '07:19 PM', timeOut: '07:49 PM', timeSpent: '00:30' },
{ id: 19, objectId: 'KA 50 MB 4266', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car192024-09-20.jpeg', timeIn: '08:15 PM', timeOut: '08:49 PM', timeSpent: '00:34' },
{ id: 20, objectId: 'KA 09 MK 2809', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car202024-09-20.jpeg', timeIn: '08:15 PM', timeOut: '08:34 PM', timeSpent: '00:19' },
{ id: 21, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car212024-09-20.jpeg', timeIn: '09:15 PM', timeOut: '', timeSpent: '' },
{ id: 22, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car222024-09-20.jpeg', timeIn: '09:17 PM', timeOut: '', timeSpent: '' }
];



} 

else if (formattedDate === '21/09/2024') {
  return [
{ id: 1, objectId: 'KA 09 Z 5540', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car12024-09-21.jpeg', timeIn: '10:00 AM', timeOut: '11:25 AM', timeSpent: '01:25' },
{ id: 2, objectId: 'KA 09 MJ 3063', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car22024-09-21.jpeg', timeIn: '10:13 AM', timeOut: '10:47 AM', timeSpent: '00:34' },
{ id: 3, objectId: 'KA-03 MM-9709', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car32024-09-21.jpeg', timeIn: '10:23 AM', timeOut: '11:13 AM', timeSpent: '00:50' },
{ id: 4, objectId: 'KA 09 MM  9944', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car42024-09-21.jpeg', timeIn: '10:33 AM', timeOut: '12:10 PM', timeSpent: '01:37' },
{ id: 5, objectId: 'KA 51 MM 6302', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car52024-09-21.jpeg', timeIn: '10:50 AM', timeOut: '12:17 PM', timeSpent: '01:27' },
{ id: 6, objectId: 'KA55N0548', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car62024-09-21.jpeg', timeIn: '11:00 AM', timeOut: '12:31 PM', timeSpent: '01:31' },
{ id: 7, objectId: 'KA 09 MB 0139', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car72024-09-21.jpeg', timeIn: '11:40 AM', timeOut: '12:52 PM', timeSpent: '01:12' },
{ id: 8, objectId: 'KA 55 N 0737', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car82024-09-21.jpeg', timeIn: '11:47 AM', timeOut: '01:10 PM', timeSpent: '01:23' },
{ id: 9, objectId: 'KA 09 MG 5511', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car92024-09-21.jpeg', timeIn: '12:24 PM', timeOut: '01:15 PM', timeSpent: '00:51' },
{ id: 10, objectId: 'KA 09 MF 1590', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car102024-09-21.jpeg', timeIn: '12:47 PM', timeOut: '01:52 PM', timeSpent: '01:05' },
{ id: 11, objectId: 'KA 11 Z 0022', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car112024-09-21.jpeg', timeIn: '01:20 PM', timeOut: '02:35 PM', timeSpent: '01:15' },
{ id: 12, objectId: 'KA 05 MN 6788', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car12024-09-21.jpeg', timeIn: '01:39 PM', timeOut: '02:44 PM', timeSpent: '01:05' },
{ id: 13, objectId: 'KA 06 P 3333', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car13024-09-21.jpeg', timeIn: '02:54 PM', timeOut: '04:06 PM', timeSpent: '01:12' },
{ id: 14, objectId: 'KA 12 MC 1530', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car142024-09-21.jpeg', timeIn: '03:07 PM', timeOut: '03:08 PM', timeSpent: '00:01' },
{ id: 15, objectId: 'KL 65 D 6381', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car152024-09-21.jpeg', timeIn: '03:39 PM', timeOut: '04:37 PM', timeSpent: '00:58' },
{ id: 16, objectId: 'KA 09 MB 3989', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car162024-09-21.jpeg', timeIn: '04:44 PM', timeOut: '05:28 PM', timeSpent: '00:44' },
{ id: 17, objectId: 'KL 46 X 1552', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car172024-09-21.jpeg', timeIn: '05:18 PM', timeOut: '07:21 PM', timeSpent: '02:03' },
{ id: 18, objectId: 'KA 09 MG 3821', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car182024-09-21.jpeg', timeIn: '05:30 PM', timeOut: '06:13 PM', timeSpent: '00:43' },
{ id: 19, objectId: 'KA 13 AS 3355', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car192024-09-21.jpeg', timeIn: '06:07 PM', timeOut: '08:43 PM', timeSpent: '02:36' },
{ id: 20, objectId: 'TS 07 EA 4027', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car202024-09-21.jpeg', timeIn: '06:21 PM', timeOut: '07:12 PM', timeSpent: '00:51' },
{ id: 21, objectId: 'KA 03 MK 9999', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car212024-09-21.jpeg', timeIn: '08:41 PM', timeOut: '09:27 PM', timeSpent: '00:46' },
{ id: 22, objectId: 'KA 09 MC 1277', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car222024-09-21.jpeg', timeIn: '06:50 PM', timeOut: '08:01 PM', timeSpent: '01:11' },
];




} 			
else if (formattedDate === '22/09/2024') {
  return [
{ id: 1, objectId: 'KA 09 N 7101', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car12024-09-22.jpeg', timeIn: '09:07 AM', timeOut: '09:54 AM', timeSpent: '00:47' },
{ id: 2, objectId: 'KA 09 MG 7671', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car22024-09-22.jpeg', timeIn: '09:17 AM', timeOut: '10:19 AM', timeSpent: '01:02' },
{ id: 3, objectId: 'KA 22 MB 8215', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car32024-09-22.jpeg', timeIn: '09:33 AM', timeOut: '10:19 AM', timeSpent: '00:46' },
{ id: 4, objectId: 'KA 12 MB 2166', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car42024-09-22.jpeg', timeIn: '09:53 AM', timeOut: '10:56 AM', timeSpent: '01:03' },
{ id: 5, objectId: 'KA 09 MH 9266', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car52024-09-22.jpeg', timeIn: '10:19 AM', timeOut: '11:19 AM', timeSpent: '01:00' },
{ id: 6, objectId: 'KA 09 MG 4905', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car62024-09-22.jpeg', timeIn: '10:29 AM', timeOut: '11:44 AM', timeSpent: '01:15' },
{ id: 7, objectId: 'KA 09 MJ 5787', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car72024-09-22.jpeg', timeIn: '10:44 AM', timeOut: '11:39 AM', timeSpent: '00:55' },
{ id: 8, objectId: 'KA 09 MF 888', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car82024-09-22.jpeg', timeIn: '10:55 AM', timeOut: '12:17 PM', timeSpent: '01:22' },
{ id: 9, objectId: 'HR 26 CH 4334', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car92024-09-22.jpeg', timeIn: '11:32 AM', timeOut: '12:56 PM', timeSpent: '01:24' },
{ id: 10, objectId: 'KA 22', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car102024-09-22.jpeg', timeIn: '11:40 AM', timeOut: '01:26 PM', timeSpent: '01:46' },
{ id: 11, objectId: 'KA 09 MC 0730', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car112024-09-22.jpeg', timeIn: '11:32 AM', timeOut: '12:44 PM', timeSpent: '01:12' },
{ id: 12, objectId: 'KA-09 MD-9658', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car122024-09-22.jpeg', timeIn: '12:05 PM', timeOut: '01:18 PM', timeSpent: '01:13' },
{ id: 13, objectId: 'KA 02 NH 7998', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car132024-09-22.jpeg', timeIn: '12:30 PM', timeOut: '05:15 PM', timeSpent: '04:45' },
{ id: 14, objectId: 'KA 09 MF 5165', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car142024-09-22.jpeg', timeIn: '01:11 PM', timeOut: '02:33 PM', timeSpent: '01:22' },
{ id: 15, objectId: '23 BH 5524 C', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car152024-09-22.jpeg', timeIn: '02:55 PM', timeOut: '04:27 PM', timeSpent: '01:32' },
{ id: 16, objectId: 'KA 55 N 0646', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car162024-09-22.jpeg', timeIn: '03:11 PM', timeOut: '04:28 PM', timeSpent: '01:17' },
{ id: 17, objectId: 'TN 38 DA 9009', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car172024-09-22.jpeg', timeIn: '03:34 PM', timeOut: '05:38 PM', timeSpent: '02:04' },
{ id: 18, objectId: 'KA 04 MG 9478', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car182024-09-22.jpeg', timeIn: '04:11 PM', timeOut: '04:55 PM', timeSpent: '00:44' },
{ id: 19, objectId: 'KA 09 MH 3330', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car192024-09-22.jpeg', timeIn: '04:28 PM', timeOut: '05:26 PM', timeSpent: '00:58' },
{ id: 20, objectId: 'KA 03 NM 8438', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car202024-09-22.jpeg', timeIn: '04:49 PM', timeOut: '05:46 PM', timeSpent: '00:57' },
{ id: 21, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car212024-09-22.jpeg', timeIn: '05:07 PM', timeOut: '07:20 PM', timeSpent: '02:13' },
{ id: 22, objectId: 'KA 09 MC 5070', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car222024-09-22.jpeg', timeIn: '05:39 PM', timeOut: '07:08 PM', timeSpent: '01:29' },
{ id: 23, objectId: 'KA 09 MD 9939', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car232024-09-22.jpeg', timeIn: '05:54 PM', timeOut: '07:01 PM', timeSpent: '01:07' },
];




} 

else if (formattedDate === '23/09/2024') {
  return [
{ id: 1, objectId: 'KA 09 ME 4350', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car12024-09-23.jpeg', timeIn: '10:43 AM', timeOut: '03:51 PM', timeSpent: '05:08' },
{ id: 2, objectId: 'KA 09 MK 4054', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car22024-09-23.jpeg', timeIn: '11:12 AM', timeOut: '11:59 AM', timeSpent: '00:47' },
{ id: 3, objectId: 'KA 03 MV 4962', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car32024-09-23.jpeg', timeIn: '01:09 PM', timeOut: '04:06 PM', timeSpent: '02:57' },
{ id: 4, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car42024-09-23.jpeg', timeIn: '01:09 PM', timeOut: '03:31 PM', timeSpent: '02:22' },
{ id: 5, objectId: 'KA 09 MA 0530', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car52024-09-23.jpeg', timeIn: '02:58 PM', timeOut: '05:21 PM', timeSpent: '02:23' },
{ id: 6, objectId: 'KA 09 MK 2809', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car62024-09-23.jpeg', timeIn: '03:48 PM', timeOut: '04:43 PM', timeSpent: '00:55' },
{ id: 7, objectId: 'KA 09 MJ 5921', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car72024-09-23.jpeg', timeIn: '04:29 PM', timeOut: '05:23 PM', timeSpent: '00:54' },
{ id: 8, objectId: 'KA 09 AA 4747', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car82024-09-23.jpeg', timeIn: '04:45 PM', timeOut: '05:21 PM', timeSpent: '00:36' },
{ id: 9, objectId: 'KA 05 MX 0216', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car92024-09-23.jpeg', timeIn: '05:14 PM', timeOut: '08:30 PM', timeSpent: '03:16' },
{ id: 10, objectId: 'KA 42 M 1213', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car102024-09-23.jpeg', timeIn: '07:32 PM', timeOut: '07:57 PM', timeSpent: '00:25' },
{ id: 11, objectId: '24 BH 6741E', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car112024-09-23.jpeg', timeIn: '08:17 PM', timeOut: '08:47 PM', timeSpent: '00:30' },
{ id: 12, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car122024-09-23.jpeg', timeIn: '09:04 PM', timeOut: '', timeSpent: '' },  // Still inside, so no timeOut or timeSpent
];

} 
else if (formattedDate === '24/09/2024') {
  return [
    { id: 1, objectId: 'KA 12 MB 7622', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car12024-09-24.jpeg', timeIn: '09:13 AM', timeOut: '10:34 AM', timeSpent: '01:21' },
    { id: 2, objectId: 'KA 09 MB 7588', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car22024-09-24.jpeg', timeIn: '09:50 AM', timeOut: '11:11 AM', timeSpent: '01:21' },
    { id: 3, objectId: 'KA 09 MF 5122', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car32024-09-24.jpeg', timeIn: '11:41 AM', timeOut: '12:38 PM', timeSpent: '00:57' },
    { id: 4, objectId: 'KA 09 MJ 4446', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car42024-09-24.jpeg', timeIn: '01:21 PM', timeOut: '02:30 PM', timeSpent: '01:09' },
    { id: 5, objectId: 'KA 09 NG 3290', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car52024-09-24.jpeg', timeIn: '03:02 PM', timeOut: '06:11 PM', timeSpent: '03:09' },
    { id: 6, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car62024-09-24.jpeg', timeIn: '03:19 PM', timeOut: '08:43 PM', timeSpent: '05:24' },
    { id: 7, objectId: 'KA 09 MK 2551', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car72024-09-24.jpeg', timeIn: '05:12 PM', timeOut: '05:36 PM', timeSpent: '00:24' },
    { id: 8, objectId: 'KA 09 MH 7136', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car82024-09-24.jpeg', timeIn: '05:47 PM', timeOut: '06:55 PM', timeSpent: '01:08' },
    { id: 9, objectId: 'KA 09 MJ 9953', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car92024-09-24.jpeg', timeIn: '07:58 PM', timeOut: '09:01 PM', timeSpent: '01:03' },
  
  
];

} 

else if (formattedDate === '25/09/2024') {
  return [
{ id: 1, objectId: 'KA 09 MK 0124', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car12024-09-25.jpeg', timeIn: '09:19 AM', timeOut: '11:21 AM', timeSpent: '02:02' },
{ id: 2, objectId: 'KA 09 MJ 5455', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car22024-09-25.jpeg', timeIn: '09:51 AM', timeOut: '10:36 AM', timeSpent: '00:45' },
{ id: 3, objectId: 'KA 03 ME 4277', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car32024-09-25.jpeg', timeIn: '10:01 AM', timeOut: '11:42 AM', timeSpent: '01:41' },
{ id: 4, objectId: 'KA 03 MA 0903', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car42024-09-25.jpeg', timeIn: '10:12 AM', timeOut: '12:03 PM', timeSpent: '01:51' },
{ id: 5, objectId: 'KA 09 MB 2401', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car52024-09-25.jpeg', timeIn: '11:47 AM', timeOut: '03:58 PM', timeSpent: '04:11' },
{ id: 6, objectId: 'KA 05 NA 9816', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car62024-09-25.jpeg', timeIn: '01:19 PM', timeOut: '02:07 PM', timeSpent: '00:48' },
{ id: 7, objectId: 'KA 01 NN 5493', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car72024-09-25.jpeg', timeIn: '02:29 PM', timeOut: '03:48 PM', timeSpent: '01:19' },
{ id: 8, objectId: 'KA 09 MK 8010', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car82024-09-25.jpeg', timeIn: '04:47 PM', timeOut: '06:03 PM', timeSpent: '01:16' },
{ id: 9, objectId: 'KA 09 MG 0711', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car92024-09-25.jpeg', timeIn: '05:03 PM', timeOut: '06:53 PM', timeSpent: '01:50' },
{ id: 10, objectId: 'KA 09 ME 4277', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car102024-09-25.jpeg', timeIn: '05:52 PM', timeOut: '06:56 PM', timeSpent: '01:04' },
{ id: 11, objectId: 'KA 01 NB 0108', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car112024-09-25.jpeg', timeIn: '05:22 PM', timeOut: '07:39 PM', timeSpent: '02:17' },
{ id: 12, objectId: 'KA 55 M 2691', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car122024-09-25.jpeg', timeIn: '05:59 PM', timeOut: '07:45 PM', timeSpent: '01:46' },
{ id: 13, objectId: 'TN 07 OF 5094', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car132024-09-25.jpeg', timeIn: '06:14 PM', timeOut: '08:28 PM', timeSpent: '02:14' },
{ id: 14, objectId: 'KA 09 MF 3895', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car142024-09-25.jpeg', timeIn: '07:06 PM', timeOut: '08:24 PM', timeSpent: '01:18' },
{ id: 15, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car152024-09-25.jpeg', timeIn: '07:41 PM', timeOut: '08:07 PM', timeSpent: '00:26' },
{ id: 16, objectId: 'KA 09 ME 8010', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car162024-09-25.jpeg', timeIn: '07:42 PM', timeOut: '09:09 PM', timeSpent: '01:27' },
];


} 

else if (formattedDate === '26/09/2024') {
  return [
    { id: 1, objectId: 'KA 09 MD 9909', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car12024-09-26.jpeg', timeIn: '09:03 AM', timeOut: '09:56 AM', timeSpent: '00:53' },
    { id: 2, objectId: 'KA 09 MB 0711', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car22024-09-26.jpeg', timeIn: '09:27 AM', timeOut: '01:32 PM', timeSpent: '04:05' },
    { id: 3, objectId: 'KA 09 MN 8486', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car32024-09-26.jpeg', timeIn: '11:56 AM', timeOut: '01:09 PM', timeSpent: '01:13' },
    { id: 4, objectId: 'KA 09 ME 7675', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car42024-09-26.jpeg', timeIn: '01:01 PM', timeOut: '02:20 PM', timeSpent: '01:19' },
    { id: 5, objectId: 'KA 09 MJ 6198', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car52024-09-26.jpeg', timeIn: '01:01 PM', timeOut: '01:20 PM', timeSpent: '00:19' },
    { id: 6, objectId: 'KA 55 N 1606', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car62024-09-26.jpeg', timeIn: '02:03 PM', timeOut: '03:05 PM', timeSpent: '01:02' },
    { id: 7, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car72024-09-26.jpeg', timeIn: '02:28 PM', timeOut: '02:58 PM', timeSpent: '00:30' },
    { id: 8, objectId: 'KL 07 GW 3232', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car82024-09-26.jpeg', timeIn: '03:13 PM', timeOut: '04:10 PM', timeSpent: '00:57' },
    { id: 9, objectId: 'KA 12 P 2119', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car92024-09-26.jpeg', timeIn: '03:29 PM', timeOut: '04:32 PM', timeSpent: '01:03' },
    { id: 10, objectId: 'TS15ES9754', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car102024-09-26.jpeg', timeIn: '05:18 PM', timeOut: '05:19 PM', timeSpent: '00:01' },
    { id: 11, objectId: 'KA 09 MJ 6384', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car112024-09-26.jpeg', timeIn: '05:54 PM', timeOut: '09:03 PM', timeSpent: '03:09' },
    { id: 12, objectId: 'KA 09 MG 5789', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car122024-09-26.jpeg', timeIn: '05:53 PM', timeOut: '07:00 PM', timeSpent: '01:07' },
    { id: 13, objectId: 'KA 09 MN 0414', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car132024-09-26.jpeg', timeIn: '02:38 PM', timeOut: '04:00 PM', timeSpent: '01:22' }
  ];

} 
else if (formattedDate === '27/09/2024') {
  return [
{ id: 1, objectId: 'KA13N7804', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car12024-09-27.jpeg', timeIn: '09:13 AM', timeOut: '10:36 AM', timeSpent: '01:23' },
{ id: 2, objectId: 'KA 09 MB 5041', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car22024-09-27.jpeg', timeIn: '09:26 AM', timeOut: '10:38 AM', timeSpent: '01:12' },
{ id: 3, objectId: 'KA 01 MD 6184', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car32024-09-27.jpeg', timeIn: '09:41 AM', timeOut: '10:48 AM', timeSpent: '01:07' },
{ id: 4, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car42024-09-27.jpeg', timeIn: '12:33 PM', timeOut: '01:54 AM', timeSpent: '13:21' },
{ id: 5, objectId: 'KA 09 MJ 3063', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car52024-09-27.jpeg', timeIn: '01:02 PM', timeOut: '02:00 PM', timeSpent: '00:58' },
{ id: 6, objectId: 'KA 09 MG 2824', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car62024-09-27.jpeg', timeIn: '01:17 PM', timeOut: '01:49 PM', timeSpent: '00:32' },
{ id: 7, objectId: 'KA 09 MG 0347', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car82024-09-27.jpeg', timeIn: '01:54 PM', timeOut: '02:15 PM', timeSpent: '00:21' },
{ id: 8, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car92024-09-27.jpeg', timeIn: '02:10 PM', timeOut: '04:40 PM', timeSpent: '02:30' },
{ id: 9, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car102024-09-27.jpeg', timeIn: '03:11 PM', timeOut: '04:29 PM', timeSpent: '01:18' },
{ id: 10, objectId: '24 BH 9692G', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car122024-09-27.jpeg', timeIn: '03:48 PM', timeOut: '05:43 PM', timeSpent: '01:55' },
{ id: 11, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car132024-09-27.jpeg', timeIn: '03:50 PM', timeOut: '04:42 PM', timeSpent: '00:52' },
{ id: 12, objectId: 'KL 19 D 7500', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car142024-09-27.jpeg', timeIn: '07:30 PM', timeOut: '07:59 PM', timeSpent: '00:29' },
{ id: 13, objectId: 'KA 09 ME 6040', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car152024-09-27.jpeg', timeIn: '07:58 PM', timeOut: '09:48 PM', timeSpent: '01:50' },
{ id: 14, objectId: 'KA 09 MF 5494', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car162024-09-27.jpeg', timeIn: '08:22 PM', timeOut: '08:24 PM', timeSpent: '00:02' },
{ id: 15, objectId: 'KA 09 MJ 3063', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car172024-09-27.jpeg', timeIn: '08:23 PM', timeOut: '09:19 PM', timeSpent: '00:56' },
{ id: 16, objectId: 'KA 09 MG 5411', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car182024-09-27.jpeg', timeIn: '09:21 PM', timeOut: '', timeSpent: '' }, // No timeOut yet
];


} 


else if (formattedDate === '28/09/2024') {
  return [
{ id: 1, objectId: 'KA 09 NN 9478', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car12024-09-29.jpeg', timeIn: '09:16 AM', timeOut: '10:24 AM', timeSpent: '01:08' },
{ id: 2, objectId: 'KA 09 MG 0828', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car22024-09-29.jpeg', timeIn: '09:57 AM', timeOut: '10:50 AM', timeSpent: '00:53' },
{ id: 3, objectId: 'KA 09 MM 9188', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car32024-09-29.jpeg', timeIn: '10:08 AM', timeOut: '11:23 AM', timeSpent: '01:15' },
{ id: 4, objectId: 'KA 05 MP 7521', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car42024-09-29.jpeg', timeIn: '10:31 AM', timeOut: '11:40 AM', timeSpent: '01:09' },
{ id: 5, objectId: 'REGD', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car52024-09-29.jpeg', timeIn: '10:48 AM', timeOut: '11:43 AM', timeSpent: '00:55' },
{ id: 6, objectId: 'KA 03 P 7383', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car62024-09-29.jpeg', timeIn: '11:03 AM', timeOut: '12:09 PM', timeSpent: '01:06' },
{ id: 7, objectId: 'KL 06 J 9162', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car72024-09-29.jpeg', timeIn: '11:22 AM', timeOut: '12:32 PM', timeSpent: '01:10' },
{ id: 8, objectId: 'KA 09 MF 5353', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car82024-09-29.jpeg', timeIn: '11:37 AM', timeOut: '12:43 PM', timeSpent: '01:06' },
{ id: 9, objectId: 'KA 09 MG 1503', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car92024-09-29.jpeg', timeIn: '12:03 PM', timeOut: '01:16 PM', timeSpent: '01:13' },
{ id: 10, objectId: 'KA 09 MJ 1525', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car102024-09-29.jpeg', timeIn: '12:19 PM', timeOut: '02:24 PM', timeSpent: '02:05' },
{ id: 11, objectId: 'TS 07 JP 4118', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car112024-09-29.jpeg', timeIn: '12:36 PM', timeOut: '01:48 PM', timeSpent: '01:12' },
{ id: 12, objectId: 'KA 05 NE 5326', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car122024-09-29.jpeg', timeIn: '12:42 PM', timeOut: '01:56 PM', timeSpent: '01:14' },
{ id: 13, objectId: 'KA 09 MJ 3822', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car132024-09-29.jpeg', timeIn: '12:33 PM', timeOut: '02:25 PM', timeSpent: '01:52' },
{ id: 14, objectId: 'KA 03 NJ 4474', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car142024-09-29.jpeg', timeIn: '02:29 PM', timeOut: '03:26 PM', timeSpent: '00:57' },
{ id: 15, objectId: 'KA 09 Z 6529', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car152024-09-29.jpeg', timeIn: '03:06 PM', timeOut: '04:34 PM', timeSpent: '01:28' },
{ id: 16, objectId: 'KA 01 MW 3434', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car162024-09-29.jpeg', timeIn: '03:10 PM', timeOut: '03:38 PM', timeSpent: '00:28' },
{ id: 17, objectId: 'RJ 48 CA 2439', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car172024-09-29.jpeg', timeIn: '03:32 PM', timeOut: '04:40 PM', timeSpent: '01:08' },
{ id: 18, objectId: 'KA 05 MW 2033', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car182024-09-29.jpeg', timeIn: '03:56 PM', timeOut: '04:42 PM', timeSpent: '00:46' },
{ id: 19, objectId: 'KA 09 MD 5352', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car192024-09-29.jpeg', timeIn: '04:07 PM', timeOut: '04:20 PM', timeSpent: '00:13' },
{ id: 20, objectId: 'KL 41 S 1377', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car202024-09-29.jpeg', timeIn: '04:20 PM', timeOut: '05:14 PM', timeSpent: '00:54' },
{ id: 21, objectId: 'KA 12 MB 7110', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car212024-09-29.jpeg', timeIn: '04:34 PM', timeOut: '05:38 PM', timeSpent: '01:04' },
{ id: 22, objectId: 'KA 11 P 3035', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car222024-09-29.jpeg', timeIn: '03:44 PM', timeOut: '06:26 PM', timeSpent: '02:42' },
{ id: 23, objectId: 'KA 09 MK 3774', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car232024-09-29.jpeg', timeIn: '05:41 PM', timeOut: '07:03 PM', timeSpent: '01:22' },
{ id: 24, objectId: 'KA 09 MH 3800', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car242024-09-29.jpeg', timeIn: '05:51 PM', timeOut: '07:34 PM', timeSpent: '01:43' },
{ id: 25, objectId: 'KA 09 MD 5352', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car252024-09-29.jpeg', timeIn: '07:05 PM', timeOut: '08:13 PM', timeSpent: '01:08' },
{ id: 26, objectId: 'KA 09 MF 9944', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car262024-09-29.jpeg', timeIn: '08:57 PM', timeOut: '', timeSpent: '' }

];

}
else if (formattedDate === '29/09/2024') {
  return [
{ id: 1, objectId: 'KA 09 NN 9478', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car12024-09-29.jpeg', timeIn: '09:16 AM', timeOut: '10:24 AM', timeSpent: '01:08' },
{ id: 2, objectId: 'KA 09 MG 0828', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car22024-09-29.jpeg', timeIn: '09:57 AM', timeOut: '10:50 AM', timeSpent: '00:53' },
{ id: 3, objectId: 'KA 09 MM 9188', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car32024-09-29.jpeg', timeIn: '10:08 AM', timeOut: '11:23 AM', timeSpent: '01:15' },
{ id: 4, objectId: 'KA 05 MP 7521', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car42024-09-29.jpeg', timeIn: '10:31 AM', timeOut: '11:40 AM', timeSpent: '01:09' },
{ id: 5, objectId: 'REGD', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car52024-09-29.jpeg', timeIn: '10:48 AM', timeOut: '11:43 AM', timeSpent: '00:55' },
{ id: 6, objectId: 'KA 03 P 7383', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car62024-09-29.jpeg', timeIn: '11:03 AM', timeOut: '12:09 PM', timeSpent: '01:06' },
{ id: 7, objectId: 'KL 06 J 9162', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car72024-09-29.jpeg', timeIn: '11:22 AM', timeOut: '12:32 PM', timeSpent: '01:10' },
{ id: 8, objectId: 'KA 09 MF 5353', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car82024-09-29.jpeg', timeIn: '11:37 AM', timeOut: '12:43 PM', timeSpent: '01:06' },
{ id: 9, objectId: 'KA 09 MG 1503', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car92024-09-29.jpeg', timeIn: '12:03 PM', timeOut: '01:16 PM', timeSpent: '01:13' },
{ id: 10, objectId: 'KA 09 MJ 1525', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car102024-09-29.jpeg', timeIn: '12:19 PM', timeOut: '02:24 PM', timeSpent: '02:05' },
{ id: 11, objectId: 'TS 07 JP 4118', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car112024-09-29.jpeg', timeIn: '12:36 PM', timeOut: '01:48 PM', timeSpent: '01:12' },
{ id: 12, objectId: 'KA 05 NE 5326', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car122024-09-29.jpeg', timeIn: '12:42 PM', timeOut: '01:56 PM', timeSpent: '01:14' },
{ id: 13, objectId: 'KA 09 MJ 3822', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car132024-09-29.jpeg', timeIn: '12:33 PM', timeOut: '02:25 PM', timeSpent: '01:52' },
{ id: 14, objectId: 'KA 03 NJ 4474', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car142024-09-29.jpeg', timeIn: '02:29 PM', timeOut: '03:26 PM', timeSpent: '00:57' },
{ id: 15, objectId: 'KA 09 Z 6529', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car152024-09-29.jpeg', timeIn: '03:06 PM', timeOut: '04:34 PM', timeSpent: '01:28' },
{ id: 16, objectId: 'KA 01 MW 3434', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car162024-09-29.jpeg', timeIn: '03:10 PM', timeOut: '03:38 PM', timeSpent: '00:28' },
{ id: 17, objectId: 'RJ 48 CA 2439', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car172024-09-29.jpeg', timeIn: '03:32 PM', timeOut: '04:40 PM', timeSpent: '01:08' },
{ id: 18, objectId: 'KA 05 MW 2033', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car182024-09-29.jpeg', timeIn: '03:56 PM', timeOut: '04:42 PM', timeSpent: '00:46' },
{ id: 19, objectId: 'KA 09 MD 5352', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car192024-09-29.jpeg', timeIn: '04:07 PM', timeOut: '04:20 PM', timeSpent: '00:13' },
{ id: 20, objectId: 'KL 41 S 1377', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car202024-09-29.jpeg', timeIn: '04:20 PM', timeOut: '05:14 PM', timeSpent: '00:54' },
{ id: 21, objectId: 'KA 12 MB 7110', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car212024-09-29.jpeg', timeIn: '04:34 PM', timeOut: '05:38 PM', timeSpent: '01:04' },
{ id: 22, objectId: 'KA 11 P 3035', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car222024-09-29.jpeg', timeIn: '03:44 PM', timeOut: '06:26 PM', timeSpent: '02:42' },
{ id: 23, objectId: 'KA 09 MK 3774', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car232024-09-29.jpeg', timeIn: '05:41 PM', timeOut: '07:03 PM', timeSpent: '01:22' },
{ id: 24, objectId: 'KA 09 MH 3800', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car242024-09-29.jpeg', timeIn: '05:51 PM', timeOut: '07:34 PM', timeSpent: '01:43' },
{ id: 25, objectId: 'KA 09 MD 5352', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car252024-09-29.jpeg', timeIn: '07:05 PM', timeOut: '08:13 PM', timeSpent: '01:08' },
{ id: 26, objectId: 'KA 09 MF 9944', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car262024-09-29.jpeg', timeIn: '08:57 PM', timeOut: '', timeSpent: '' }




];

}

else if (formattedDate === '30/09/2024') {
  return [
{ id: 1, objectId: 'KA 09 MD 4878', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car12024-09-30.jpeg', timeIn: '09:20 AM', timeOut: '12:26 PM', timeSpent: '03:06' },
{ id: 2, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car22024-09-30.jpeg', timeIn: '09:23 AM', timeOut: '10:45 AM', timeSpent: '01:22' },
{ id: 3, objectId: 'KA 09 MJ 6951', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car32024-09-30.jpeg', timeIn: '10:40 AM', timeOut: '12:05 PM', timeSpent: '01:25' },
{ id: 4, objectId: 'KA 09 P 3963', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car42024-09-30.jpeg', timeIn: '11:07 AM', timeOut: '11:41 AM', timeSpent: '00:34' },
{ id: 5, objectId: 'KA 01 MB 3856', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car52024-09-30.jpeg', timeIn: '11:42 AM', timeOut: '01:16 PM', timeSpent: '01:34' },
{ id: 6, objectId: 'KA 01 NB 0108', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car62024-09-30.jpeg', timeIn: '12:17 PM', timeOut: '02:17 PM', timeSpent: '02:00' },
{ id: 7, objectId: 'KA 10 A 1973', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car72024-09-30.jpeg', timeIn: '11:44 AM', timeOut: '12:58 PM', timeSpent: '01:14' },
{ id: 8, objectId: 'KA 09 MK 2810', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car82024-09-30.jpeg', timeIn: '12:35 PM', timeOut: '02:22 PM', timeSpent: '01:47' },
{ id: 9, objectId: 'KA 09 MF 8603', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car92024-09-30.jpeg', timeIn: '01:33 PM', timeOut: '04:51 PM', timeSpent: '03:18' },
{ id: 10, objectId: 'KA 05 NG 8449', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car102024-09-30.jpeg', timeIn: '04:22 PM', timeOut: '06:34 PM', timeSpent: '02:12' },
{ id: 11, objectId: 'KA 09 MJ 5455', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car112024-09-30.jpeg', timeIn: '04:52 PM', timeOut: '07:08 PM', timeSpent: '02:16' },
{ id: 12, objectId: 'TO824KL4338J', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car122024-09-30.jpeg', timeIn: '05:26 PM', timeOut: '05:58 PM', timeSpent: '00:32' },
{ id: 13, objectId: 'KA04MU5508', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car132024-09-30.jpeg', timeIn: '05:51 PM', timeOut: '06:38 PM', timeSpent: '00:47' },
{ id: 14, objectId: 'KA09AA4747', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car142024-09-30.jpeg', timeIn: '06:31 PM', timeOut: '07:58 PM', timeSpent: '01:27' },
{ id: 15, objectId: 'KA 01 MB 3856', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car152024-09-30.jpeg', timeIn: '09:14 PM', timeOut: '', timeSpent: '' }
];

}
else if (formattedDate === '01/10/2024') {
              return [
 { id: 1, objectId: 'KA 10 M 2919', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car12024-10-01.jpeg', timeIn: '09:17 AM', timeOut: '11:33 AM', timeSpent: '02:16' },
  { id: 2, objectId: 'KA 09 MD 6724', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car22024-10-01.jpeg', timeIn: '10:35 AM', timeOut: '11:46 AM', timeSpent: '01:11' },
  { id: 3, objectId: 'KA 19 MK 0879', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car32024-10-01.jpeg', timeIn: '10:53 AM', timeOut: '12:28 PM', timeSpent: '01:35' },
  { id: 4, objectId: 'KA 09 MC 6676', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car42024-10-01.jpeg', timeIn: '11:36 AM', timeOut: '12:48 PM', timeSpent: '01:12' },
  { id: 5, objectId: 'KA 01 MM 5493', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car52024-10-01.jpeg', timeIn: '01:05 PM', timeOut: '02:15 PM', timeSpent: '01:10' },
  { id: 6, objectId: 'KA 01 NB 3856', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car62024-10-01.jpeg', timeIn: '01:30 PM', timeOut: '02:09 PM', timeSpent: '00:39' },
  { id: 7, objectId: 'KA 09 ME 7273', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car72024-10-01.jpeg', timeIn: '03:14 PM', timeOut: '04:32 PM', timeSpent: '01:18' },
  { id: 8, objectId: 'KA 05 MN 6788', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car82024-10-01.jpeg', timeIn: '03:44 PM', timeOut: '04:52 PM', timeSpent: '01:08' },
  { id: 9, objectId: 'KA 47 M 5038', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car92024-10-01.jpeg', timeIn: '04:46 PM', timeOut: '06:07 PM', timeSpent: '01:21' },
  { id: 10, objectId: 'KA 09 MG 4167', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car102024-10-01.jpeg', timeIn: '05:13 PM', timeOut: '06:44 PM', timeSpent: '01:31' },
  { id: 11, objectId: 'KA 09 MJ 9953', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car112024-10-01.jpeg', timeIn: '07:38 PM', timeOut: '08:32 PM', timeSpent: '00:54' },
  { id: 12, objectId: 'TN 07 CQ 7258', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car122024-10-01.jpeg', timeIn: '07:55 PM', timeOut: '08:26 PM', timeSpent: '00:31' },
  { id: 13, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car132024-10-01.jpeg', timeIn: '07:29 PM', timeOut: '', timeSpent: '' }
];

}

else if (formattedDate === '02/10/2024') {
              return [
			  
	
  { id: 1, objectId: 'KA 09 MK 1763', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car12024-10-02.jpeg', timeIn: '09:39 AM', timeOut: '10:48 AM', timeSpent: '01:09' },
  { id: 2, objectId: 'KA 02 MS 4096', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car22024-10-02.jpeg', timeIn: '09:48 AM', timeOut: '11:08 AM', timeSpent: '01:20' },
  { id: 3, objectId: 'KA 09 MF 3072', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car32024-10-02.jpeg', timeIn: '10:03 AM', timeOut: '11:21 AM', timeSpent: '01:18' },
  { id: 4, objectId: 'KA 09 ME 6851', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car42024-10-02.jpeg', timeIn: '10:13 AM', timeOut: '12:16 PM', timeSpent: '02:03' },
  { id: 5, objectId: 'KA 09 MK 2422', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car52024-10-02.jpeg', timeIn: '12:01 PM', timeOut: '01:26 PM', timeSpent: '01:25' },
  { id: 6, objectId: '23 BH 4397 J', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car62024-10-02.jpeg', timeIn: '01:16 PM', timeOut: '02:25 PM', timeSpent: '01:09' },
  { id: 7, objectId: 'AP 40 BS 2222', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car72024-10-02.jpeg', timeIn: '01:50 PM', timeOut: '02:11 PM', timeSpent: '00:21' },
  { id: 8, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car82024-10-02.jpeg', timeIn: '02:00 PM', timeOut: '03:29 PM', timeSpent: '01:29' },
  { id: 9, objectId: 'KA 09 MK 0886', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car92024-10-02.jpeg', timeIn: '02:29 PM', timeOut: '03:51 PM', timeSpent: '01:22' },
  { id: 10, objectId: 'KA 09 Z 3418', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car102024-10-02.jpeg', timeIn: '02:55 PM', timeOut: '04:14 PM', timeSpent: '01:19' },
  { id: 11, objectId: 'KA 09 MA 1023', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car112024-10-02.jpeg', timeIn: '03:24 PM', timeOut: '05:31 PM', timeSpent: '02:07' },
  { id: 12, objectId: 'KA 09 Z 5527', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car122024-10-02.jpeg', timeIn: '03:31 PM', timeOut: '04:44 PM', timeSpent: '01:13' },
  { id: 13, objectId: 'KA 09 MF 2894', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car132024-10-02.jpeg', timeIn: '04:41 PM', timeOut: '07:34 PM', timeSpent: '02:53' },
  { id: 14, objectId: 'KA 09 ME 4350', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car142024-10-02.jpeg', timeIn: '03:56 PM', timeOut: '03:58 PM', timeSpent: '00:02' },
  { id: 15, objectId: 'KA 14 P 4431', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car152024-10-02.jpeg', timeIn: '05:04 PM', timeOut: '05:10 PM', timeSpent: '00:06' },
  { id: 16, objectId: 'KA 05 MK 7327', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car162024-10-02.jpeg', timeIn: '05:11 PM', timeOut: '05:52 PM', timeSpent: '00:41' },
  { id: 17, objectId: 'KA 55 N 2073', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car172024-10-02.jpeg', timeIn: '05:53 PM', timeOut: '07:01 PM', timeSpent: '01:08' },
  { id: 18, objectId: 'KA 09 NH 0828', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car182024-10-02.jpeg', timeIn: '06:43 PM', timeOut: '08:00 PM', timeSpent: '01:17' },
  { id: 20, objectId: 'KA 04 MM 3472', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car202024-10-02.jpeg', timeIn: '08:49 PM', timeOut: '', timeSpent: '' }


			  
			  
  ];

}

else if (formattedDate === '03/10/2024') {
  return [


{ id: 1, objectId: 'KL09ML0909', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car12024-10-03.jpeg', timeIn: '10:33 AM', timeOut: '04:00 PM', timeSpent: '05:27' },
{ id: 2, objectId: 'KA09MH0558', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car22024-10-03.jpeg', timeIn: '10:44 AM', timeOut: '12:08 PM', timeSpent: '01:24' },
{ id: 3, objectId: 'KA05NG4728', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car32024-10-03.jpeg', timeIn: '11:45 AM', timeOut: '01:42 PM', timeSpent: '01:57' },
{ id: 4, objectId: 'KA0 1MF 7072', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car42024-10-03.jpeg', timeIn: '01:05 PM', timeOut: '01:57 PM', timeSpent: '00:52' },
{ id: 5, objectId: 'KA 09M G-570 ', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car52024-10-03.jpeg', timeIn: '01:19 PM', timeOut: '02:21 PM', timeSpent: '01:02' },
{ id: 6, objectId: 'KA05NF8626', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car62024-10-03.jpeg', timeIn: '01:21 PM', timeOut: '02:34 PM', timeSpent: '01:13' },
{ id: 7, objectId: 'KA1 1ML 4814', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car72024-10-03.jpeg', timeIn: '01:50 PM', timeOut: '02:55 PM', timeSpent: '01:05' },
{ id: 8, objectId: 'KA09MG2094', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car82024-10-03.jpeg', timeIn: '02:56 PM', timeOut: '04:06 PM', timeSpent: '01:10' },
{ id: 9, objectId: 'KA 09 MF 0984', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car92024-10-03.jpeg', timeIn: '03:15 PM', timeOut: '04:00 PM', timeSpent: '00:45' },
{ id: 10, objectId: 'KA 09 NF 9994', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car102024-10-03.jpeg', timeIn: '04:50 PM', timeOut: '06:00 PM', timeSpent: '01:10' },
{ id: 11, objectId: 'KA 09 ME 4350', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car112024-10-03.jpeg', timeIn: '05:00 PM', timeOut: '05:45 PM', timeSpent: '00:45' },
{ id: 12, objectId: 'KA 09 MH 1496', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car122024-10-03.jpeg', timeIn: '05:00 PM', timeOut: '05:20 PM', timeSpent: '00:20' },
{ id: 13, objectId: 'KA 06 Z 3082', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car132024-10-03.jpeg', timeIn: '05:45 PM', timeOut: '06:10 PM', timeSpent: '00:25' },
{ id: 14, objectId: 'KA03MK5706', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car142024-10-03.jpeg', timeIn: '06:15 PM', timeOut: '07:10 PM', timeSpent: '00:55' },
{ id: 15, objectId: 'KA04MY5802', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car152024-10-03.jpeg', timeIn: '06:40 PM', timeOut: '08:15 PM', timeSpent: '01:35' },
{ id: 16, objectId: 'KA 03 MT 4753', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car162024-10-03.jpeg', timeIn: '07:20 PM', timeOut: '08:00 PM', timeSpent: '00:40' },
{ id: 17, objectId: 'KA 01 MC 0796', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car172024-10-03.jpeg', timeIn: '06:48 PM', timeOut: '08:30 PM', timeSpent: '01:42' }


];

}

else if (formattedDate === '04/10/2024') {
  return [


{ id: 1, objectId: 'KA 17 MB 9068', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car12024-10-04.jpeg', timeIn: '09:02 AM', timeOut: '09:34 AM', timeSpent: '00:32' },
{ id: 2, objectId: '22 BH 2296 O', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car22024-10-04.jpeg', timeIn: '09:52 AM', timeOut: '12:05 PM', timeSpent: '02:13' },
{ id: 3, objectId: 'KA 05 NH 7423', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car32024-10-04.jpeg', timeIn: '09:56 AM', timeOut: '11:01 AM', timeSpent: '01:05' },
{ id: 4, objectId: 'KA 09 MH 8106', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car42024-10-04.jpeg', timeIn: '10:11 AM', timeOut: '11:34 AM', timeSpent: '01:23' },
{ id: 5, objectId: 'KA 09 MJ 3993', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car52024-10-04.jpeg', timeIn: '11:15 AM', timeOut: '01:08 PM', timeSpent: '01:53' },
{ id: 6, objectId: 'KA 09 MJ 2178', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car62024-10-04.jpeg', timeIn: '11:29 AM', timeOut: '12:26 PM', timeSpent: '00:57' },
{ id: 7, objectId: 'KL 04 AT 6336', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car72024-10-04.jpeg', timeIn: '11:51 AM', timeOut: '12:58 PM', timeSpent: '01:07' },
{ id: 8, objectId: 'KA 09 MC 9436', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car82024-10-04.jpeg', timeIn: '11:59 AM', timeOut: '12:42 PM', timeSpent: '00:43' },
{ id: 9, objectId: 'KA 09 MK 4054', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car92024-10-04.jpeg', timeIn: '12:17 PM', timeOut: '01:10 PM', timeSpent: '00:53' },
{ id: 10, objectId: 'KA09MK0107', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car102024-10-04.jpeg', timeIn: '01:40 PM', timeOut: '03:02 PM', timeSpent: '01:22' },
{ id: 11, objectId: 'KA 09 MC 4804', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car112024-10-04.jpeg', timeIn: '01:43 PM', timeOut: '02:49 PM', timeSpent: '01:06' },
{ id: 12, objectId: 'KA 12 MA 7580', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car122024-10-04.jpeg', timeIn: '01:52 PM', timeOut: '05:34 PM', timeSpent: '03:42' },
{ id: 13, objectId: 'KA 08 MO 0466', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car132024-10-04.jpeg', timeIn: '03:13 PM', timeOut: '05:28 PM', timeSpent: '02:15' },
{ id: 14, objectId: 'KA 09 MC 5627', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car142024-10-04.jpeg', timeIn: '03:28 PM', timeOut: '04:08 PM', timeSpent: '00:40' },
{ id: 15, objectId: 'KA 09 MJ 6198', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car152024-10-04.jpeg', timeIn: '05:15 PM', timeOut: '06:20 PM', timeSpent: '01:05' },
{ id: 16, objectId: 'KA10M7444', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car162024-10-04.jpeg', timeIn: '05:29 PM', timeOut: '06:55 PM', timeSpent: '01:26' },
{ id: 17, objectId: 'KA05Z8258', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car172024-10-04.jpeg', timeIn: '07:30 PM', timeOut: '08:50 PM', timeSpent: '01:20' }


];

}

else if (formattedDate === '05/10/2024') {
  return [

{ id: 1, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car12024-10-05.jpeg', timeIn: '10:56 AM', timeOut: '11:53 AM', timeSpent: '00:57' },
{ id: 2, objectId: 'KA 03 MZ 6099', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car22024-10-05.jpeg', timeIn: '12:08 PM', timeOut: '02:23 PM', timeSpent: '02:15' },
{ id: 3, objectId: 'KA 09 MH 1980', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car32024-10-05.jpeg', timeIn: '12:36 PM', timeOut: '01:38 PM', timeSpent: '01:02' },
{ id: 4, objectId: 'KA 12 MB 5744', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car42024-10-05.jpeg', timeIn: '01:22 PM', timeOut: '02:53 PM', timeSpent: '01:31' },
{ id: 5, objectId: 'KA 41 M 9863', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car52024-10-05.jpeg', timeIn: '01:33 PM', timeOut: '07:14 PM', timeSpent: '05:41' },
{ id: 6, objectId: 'KA 51 MO 9848', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car62024-10-05.jpeg', timeIn: '03:27 PM', timeOut: '05:17 PM', timeSpent: '01:50' },
{ id: 7, objectId: 'KA 09 MH 1001', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car72024-10-05.jpeg', timeIn: '04:27 PM', timeOut: '05:22 PM', timeSpent: '00:55' },
{ id: 8, objectId: 'KA 09 MC 0826', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car82024-10-05.jpeg', timeIn: '04:51 PM', timeOut: '05:57 PM', timeSpent: '01:06' },
{ id: 9, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car92024-10-05.jpeg', timeIn: '03:27 PM', timeOut: '', timeSpent: '' } // Missing timeOut, so timeSpent remains empty


];

}

else if (formattedDate === '06/10/2024') {
  return [


{ id: 1, objectId: 'KA 05 NH 4667', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car12024-10-06.jpeg', timeIn: '09:07 AM', timeOut: '10:18 AM', timeSpent: '01:11' },
{ id: 2, objectId: 'KA 53 MJ 4607', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car22024-10-06.jpeg', timeIn: '09:16 AM', timeOut: '10:28 AM', timeSpent: '01:12' },
{ id: 3, objectId: 'KA 05 MU 3066', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car32024-10-06.jpeg', timeIn: '10:31 AM', timeOut: '11:22 AM', timeSpent: '00:51' },
{ id: 4, objectId: 'KA 09 MF 8759', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car42024-10-06.jpeg', timeIn: '10:38 AM', timeOut: '12:24 PM', timeSpent: '01:46' },
{ id: 5, objectId: 'KA 03 SZ 8333', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car52024-10-06.jpeg', timeIn: '10:41 AM', timeOut: '12:20 PM', timeSpent: '01:39' },
{ id: 6, objectId: 'CG 04 NN 2310', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car62024-10-06.jpeg', timeIn: '10:57 AM', timeOut: '11:59 AM', timeSpent: '01:02' },
{ id: 7, objectId: 'KA 09 MB 4950', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car72024-10-06.jpeg', timeIn: '11:16 AM', timeOut: '12:44 PM', timeSpent: '01:28' },
{ id: 8, objectId: 'KA 01 MJ 0607', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car82024-10-06.jpeg', timeIn: '11:17 AM', timeOut: '12:55 PM', timeSpent: '01:38' },
{ id: 9, objectId: 'KA 41 MD 1836', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car92024-10-06.jpeg', timeIn: '11:38 AM', timeOut: '12:41 PM', timeSpent: '01:03' },
{ id: 10, objectId: 'KA 01 MH 7732', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car102024-10-06.jpeg', timeIn: '12:17 PM', timeOut: '01:16 PM', timeSpent: '00:59' },
{ id: 11, objectId: 'KA 09 MH 8746', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car112024-10-06.jpeg', timeIn: '12:21 PM', timeOut: '02:09 PM', timeSpent: '01:48' },
{ id: 12, objectId: 'KA 04 MW 5425', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car122024-10-06.jpeg', timeIn: '01:06 PM', timeOut: '02:18 PM', timeSpent: '01:12' },
{ id: 13, objectId: 'KA 09 MH 0939', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car132024-10-06.jpeg', timeIn: '01:15 PM', timeOut: '05:33 PM', timeSpent: '04:18' },
{ id: 14, objectId: 'T0924KL0053L', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car142024-10-06.jpeg', timeIn: '01:47 PM', timeOut: '02:45 PM', timeSpent: '00:58' },
{ id: 15, objectId: 'KA 45 M 6428', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car152024-10-06.jpeg', timeIn: '02:36 PM', timeOut: '03:38 PM', timeSpent: '01:02' },
{ id: 16, objectId: 'KA 53 M 4127', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car162024-10-06.jpeg', timeIn: '03:09 PM', timeOut: '04:05 PM', timeSpent: '00:56' },
{ id: 17, objectId: 'KA 05 NE 0193', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car172024-10-06.jpeg', timeIn: '04:09 PM', timeOut: '04:57 PM', timeSpent: '00:48' },
{ id: 18, objectId: 'KA 09 MJ 5455', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car182024-10-06.jpeg', timeIn: '04:23 PM', timeOut: '06:32 PM', timeSpent: '02:09' },
{ id: 19, objectId: 'KA 09 MK 6633', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car192024-10-06.jpeg', timeIn: '04:23 PM', timeOut: '05:16 PM', timeSpent: '00:53' },
{ id: 20, objectId: 'KA 09 MG 9092', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car212024-10-06.jpeg', timeIn: '05:06 PM', timeOut: '05:47 PM', timeSpent: '00:41' },
{ id: 21, objectId: 'KA 09 MA 8509', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car222024-10-06.jpeg', timeIn: '05:21 PM', timeOut: '06:21 PM', timeSpent: '01:00' },
{ id: 22, objectId: 'KA 09 MA 1023', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car232024-10-06.jpeg', timeIn: '06:07 PM', timeOut: '08:27 PM', timeSpent: '02:20' },
{ id: 23, objectId: 'KA 09 MJ 8232', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car242024-10-06.jpeg', timeIn: '06:22 PM', timeOut: '07:54 PM', timeSpent: '01:32' },
{ id: 24, objectId: 'KA 09 MH 6459', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car252024-10-06.jpeg', timeIn: '06:34 PM', timeOut: '07:26 PM', timeSpent: '00:52' },
{ id: 25, objectId: 'KA 09 AA 4747', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car262024-10-06.jpeg', timeIn: '06:48 PM', timeOut: '07:39 PM', timeSpent: '00:51' }



];

}


else if (formattedDate === '07/10/2024') {
  return [

{ id: 1, objectId: 'KA 09 MH 4544', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car12024-10-07.jpeg', timeIn: '09:20 AM', timeOut: '10:19 AM', timeSpent: '00:59' },
{ id: 2, objectId: 'KA 09 MG 7677', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car22024-10-07.jpeg', timeIn: '09:40 AM', timeOut: '09:49 AM', timeSpent: '00:09' },
{ id: 3, objectId: 'TS 07 GH 5616', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car32024-10-07.jpeg', timeIn: '09:47 AM', timeOut: '10:16 AM', timeSpent: '00:29' },
{ id: 4, objectId: 'KA 09 MJ 8830', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car42024-10-07.jpeg', timeIn: '10:05 AM', timeOut: '01:42 PM', timeSpent: '03:37' },
{ id: 5, objectId: 'KA 05 MS 8899', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car52024-10-07.jpeg', timeIn: '10:11 AM', timeOut: '10:36 AM', timeSpent: '00:25' },
{ id: 6, objectId: 'KA 09 MC 4131', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car62024-10-07.jpeg', timeIn: '10:32 AM', timeOut: '12:40 PM', timeSpent: '02:08' },
{ id: 7, objectId: 'KA 09 ME 7298', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car72024-10-07.jpeg', timeIn: '10:58 AM', timeOut: '11:49 AM', timeSpent: '00:51' },
{ id: 8, objectId: 'KA 01 MX 5935', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car82024-10-07.jpeg', timeIn: '12:01 PM', timeOut: '03:16 PM', timeSpent: '03:15' },
{ id: 9, objectId: 'KA 09 MA 7152', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car92024-10-07.jpeg', timeIn: '12:23 PM', timeOut: '01:01 PM', timeSpent: '00:38' },
{ id: 10, objectId: 'PB 1G EX 0021', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car102024-10-07.jpeg', timeIn: '12:47 PM', timeOut: '01:12 PM', timeSpent: '00:25' },
{ id: 11, objectId: 'KA 09 MK 1632', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car112024-10-07.jpeg', timeIn: '01:22 PM', timeOut: '03:13 PM', timeSpent: '01:51' },
{ id: 12, objectId: 'KA 04 MS 2655', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car122024-10-07.jpeg', timeIn: '01:33 PM', timeOut: '02:02 PM', timeSpent: '00:29' },
{ id: 13, objectId: 'KA 09 MC 4272', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car132024-10-07.jpeg', timeIn: '02:18 PM', timeOut: '02:52 PM', timeSpent: '00:34' },
{ id: 14, objectId: 'KA 09 MK 2809', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car142024-10-07.jpeg', timeIn: '02:36 PM', timeOut: '04:01 PM', timeSpent: '01:25' },
{ id: 15, objectId: 'KA 01 MD 8076', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car152024-10-07.jpeg', timeIn: '03:38 PM', timeOut: '04:14 PM', timeSpent: '00:36' },
{ id: 17, objectId: 'KA 09 ME 2329', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car172024-10-07.jpeg', timeIn: '03:09 PM', timeOut: '03:49 PM', timeSpent: '00:40' },
{ id: 18, objectId: 'KA 18 M 4707', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car182024-10-07.jpeg', timeIn: '03:17 PM', timeOut: '04:05 PM', timeSpent: '00:48' },
{ id: 19, objectId: 'TN 43 R 0333', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car192024-10-07.jpeg', timeIn: '03:42 PM', timeOut: '04:46 PM', timeSpent: '01:04' },
{ id: 20, objectId: 'KA 01 NM 5493', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car202024-10-07.jpeg', timeIn: '03:51 PM', timeOut: '05:07 PM', timeSpent: '01:16' },
{ id: 21, objectId: 'KA 09 MF 4114', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car212024-10-07.jpeg', timeIn: '04:16 PM', timeOut: '06:22 PM', timeSpent: '02:06' },
{ id: 22, objectId: 'KA 09 MC 6116', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car222024-10-07.jpeg', timeIn: '04:06 PM', timeOut: '06:01 PM', timeSpent: '01:55' },
{ id: 23, objectId: 'KA 05 MK 3915', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car232024-10-07.jpeg', timeIn: '04:38 PM', timeOut: '06:02 PM', timeSpent: '01:24' },
{ id: 24, objectId: 'REGISTERED', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car242024-10-07.jpeg', timeIn: '05:47 PM', timeOut: '', timeSpent: '' },
{ id: 25, objectId: 'MH 09 DM 7384', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car252024-10-07.jpeg', timeIn: '06:18 PM', timeOut: '08:11 PM', timeSpent: '01:53' },
{ id: 26, objectId: 'KA 09 MG 7959', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car262024-10-07.jpeg', timeIn: '06:42 PM', timeOut: '07:52 PM', timeSpent: '01:10' },
{ id: 27, objectId: 'KA 09 MD 2575', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car272024-10-07.jpeg', timeIn: '07:06 PM', timeOut: '07:49 PM', timeSpent: '00:43' },
{ id: 28, objectId: '23 BH 5524 C', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car282024-10-07.jpeg', timeIn: '07:23 PM', timeOut: '08:42 PM', timeSpent: '01:19' },
{ id: 29, objectId: 'PB 1G EX 0021', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car292024-10-07.jpeg', timeIn: '08:11 PM', timeOut: '', timeSpent: '' }

];

}
else if (formattedDate === '08/10/2024') {
  return [

{ id: 1, objectId: 'KA 03 MB 6145', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car12024-10-08.jpeg', timeIn: '09:11 AM', timeOut: '10:02 AM', timeSpent: '00:45' },
{ id: 2, objectId: 'KA 09 MJ 2095', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car22024-10-08.jpeg', timeIn: '09:24 AM', timeOut: '11:24 AM', timeSpent: '02:00' },
{ id: 3, objectId: 'KA 09 ME 6823', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car32024-10-08.jpeg', timeIn: '09:24 AM', timeOut: '10:26 AM', timeSpent: '01:02' },
{ id: 4, objectId: 'KA 51 MA 8588', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car42024-10-08.jpeg', timeIn: '10:16 AM', timeOut: '11:23 AM', timeSpent: '01:07' },
{ id: 5, objectId: 'KA 09 MF 1653', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car52024-10-08.jpeg', timeIn: '10:31 AM', timeOut: '11:29 AM', timeSpent: '00:58' },
{ id: 6, objectId: 'KA 09 MC 0609', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car62024-10-08.jpeg', timeIn: '11:27 AM', timeOut: '12:27 PM', timeSpent: '01:00' },
{ id: 7, objectId: 'KA 09 MK 3432', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car72024-10-08.jpeg', timeIn: '11:53 AM', timeOut: '01:00 PM', timeSpent: '01:07' },
{ id: 8, objectId: 'KA 12 P 4630', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car82024-10-08.jpeg', timeIn: '11:55 AM', timeOut: '02:32 PM', timeSpent: '02:37' },
{ id: 9, objectId: 'KA 12 MB 9221', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car92024-10-08.jpeg', timeIn: '12:15 PM', timeOut: '01:11 PM', timeSpent: '00:56' },
{ id: 10, objectId: 'KA 09 Z 3565', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car102024-10-08.jpeg', timeIn: '01:40 PM', timeOut: '02:03 PM', timeSpent: '00:23' },
{ id: 11, objectId: 'KA 09 MJ 9291', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car112024-10-08.jpeg', timeIn: '01:53 PM', timeOut: '05:33 PM', timeSpent: '03:40' },
{ id: 12, objectId: 'KA 15 2990', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car122024-10-08.jpeg', timeIn: '02:14 PM', timeOut: '03:33 PM', timeSpent: '01:19' },
{ id: 13, objectId: 'KA 20 MB 7440', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car132024-10-08.jpeg', timeIn: '02:25 PM', timeOut: '03:54 PM', timeSpent: '01:29' },
{ id: 14, objectId: 'KA 01 MT 9449', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car142024-10-08.jpeg', timeIn: '02:41 PM', timeOut: '04:15 PM', timeSpent: '01:34' },
{ id: 15, objectId: 'KA 09 MB 1917', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car152024-10-08.jpeg', timeIn: '03:06 PM', timeOut: '04:35 PM', timeSpent: '01:29' },
{ id: 16, objectId: 'KL 18 AA 7570', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car162024-10-08.jpeg', timeIn: '03:37 PM', timeOut: '03:45 PM', timeSpent: '00:08' },
{ id: 17, objectId: 'KA 53 N 8783', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car172024-10-08.jpeg', timeIn: '03:45 PM', timeOut: '04:51 PM', timeSpent: '01:06' },
{ id: 18, objectId: 'PY 05 YD 6444', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car182024-10-08.jpeg', timeIn: '04:46 PM', timeOut: '05:03 PM', timeSpent: '00:17' },
{ id: 19, objectId: 'KA 03 ND 8044', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car192024-10-08.jpeg', timeIn: '04:26 PM', timeOut: '06:43 PM', timeSpent: '02:17' },
{ id: 20, objectId: 'KA-55-N-0148', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car202024-10-08.jpeg', timeIn: '04:32 PM', timeOut: '08:22 PM', timeSpent: '03:50' },
{ id: 21, objectId: 'KA 09 NH 3551', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car212024-10-08.jpeg', timeIn: '04:48 PM', timeOut: '06:24 PM', timeSpent: '01:36' },
{ id: 22, objectId: 'KA 03 MP 1864', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car222024-10-08.jpeg', timeIn: '05:01 PM', timeOut: '05:05 PM', timeSpent: '00:04' },
{ id: 23, objectId: 'TN 10 BP 8700', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car232024-10-08.jpeg', timeIn: '05:23 PM', timeOut: '06:39 PM', timeSpent: '01:16' },
{ id: 24, objectId: 'KA 12 MA 5065', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car242024-10-08.jpeg', timeIn: '05:39 PM', timeOut: '07:07 PM', timeSpent: '01:28' },
{ id: 25, objectId: 'KA 09 MG 7671', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car252024-10-08.jpeg', timeIn: '07:12 PM', timeOut: '08:26 PM', timeSpent: '01:14' },
{ id: 26, objectId: 'KA 09 MF 8008', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car262024-10-08.jpeg', timeIn: '07:22 PM', timeOut: '07:59 PM', timeSpent: '00:37' },
{ id: 27, objectId: 'KL 39 U 2002', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car272024-10-08.jpeg', timeIn: '07:32 PM', timeOut: '08:21 PM', timeSpent: '00:49' },
{ id: 28, objectId: 'KA 09 MK 2442', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car282024-10-08.jpeg', timeIn: '08:06 PM', timeOut: '09:22 PM', timeSpent: '01:16' },
{ id: 29, objectId: 'KA 09 MG 5740', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car292024-10-08.jpeg', timeIn: '07:47 PM', timeOut: '09:51 PM', timeSpent: '02:04' },
{ id: 30, objectId: 'KA 04 MB 5584', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car302024-10-08.jpeg', timeIn: '08:23 PM', timeOut: '09:40 PM', timeSpent: '01:17' }




];

}

else if (formattedDate === '09/10/2024') {
  return [
{ id:1, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car12024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:2, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car22024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:3, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car32024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:4, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car42024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:5, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car52024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:6, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car62024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:7, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car72024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:8, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car82024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:9, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car92024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:10, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car102024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:11, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car112024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:12, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car122024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:13, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car132024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:14, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car142024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:15, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car152024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:16, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car162024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:17, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car172024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:18, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car182024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:19, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car192024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:20, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car202024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:21, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car212024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:22, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car222024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:23, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car232024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:24, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car242024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:25, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car252024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:26, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car262024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:27, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car272024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:28, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car282024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:29, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car292024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:30, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car302024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:31, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car312024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:32, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car322024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:33, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car332024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:34, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car342024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:35, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car352024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:36, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car362024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:37, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car372024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:38, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car382024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:39, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car392024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:40, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car402024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:41, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car412024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:42, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car422024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:43, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car432024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:44, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car442024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:45, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car452024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:46, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car462024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:47, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car472024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:48, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car482024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:49, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car492024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:50, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car502024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:51, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car512024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:52, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car522024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:53, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car532024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:54, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car542024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:55, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car552024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:56, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car562024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:57, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car572024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:58, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car582024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:59, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car592024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:60, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car602024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:61, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car612024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:62, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car622024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:63, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car632024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:64, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car642024-10-09.jpeg',timeIn: '', timeOut: '', timeSpent: '' },





];

}
else if (formattedDate === '10/10/2024') {
  return [

{ id:1, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car12024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:2, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car22024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:3, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car32024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:4, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car42024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:5, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car52024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:6, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car62024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:7, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car72024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:8, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car82024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:9, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car92024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:10, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car102024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:11, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car112024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:12, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car122024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:13, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car132024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:14, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car142024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:15, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car152024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:16, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car162024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:17, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car172024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:18, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car182024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:19, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car192024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:20, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car202024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:21, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car212024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:22, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car222024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:23, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car232024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:24, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car242024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:25, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car252024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:26, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car262024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:27, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car272024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:28, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car282024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:29, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car292024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:30, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car302024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:31, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car312024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:32, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car322024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:33, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car332024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:34, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car342024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:35, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car352024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:36, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car362024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:37, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car372024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:38, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car382024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:39, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car392024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:40, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car402024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:41, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car412024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:42, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car422024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:43, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car432024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:44, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car442024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:45, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car452024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:46, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car462024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:47, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car472024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:48, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car482024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:49, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car492024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:50, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car502024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:51, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car512024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:52, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car522024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:53, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car532024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:54, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car542024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:55, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car552024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:56, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car562024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:57, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car572024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:58, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car582024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:59, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car592024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:60, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car602024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:61, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car612024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:62, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car622024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:63, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car632024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:64, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car642024-10-10.jpeg',timeIn: '', timeOut: '', timeSpent: '' },



];

}   
else if (formattedDate === '11/10/2024') {
  return [

{ id:1, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car12024-10-11.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:2, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car22024-10-11.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:3, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car32024-10-11.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:4, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car42024-10-11.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:5, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car52024-10-11.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:6, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car62024-10-11.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:7, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car72024-10-11.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:8, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car82024-10-11.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:9, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car92024-10-11.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:10, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car102024-10-11.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:11, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car112024-10-11.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:12, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car122024-10-11.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:13, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car132024-10-11.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:14, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car142024-10-11.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:15, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car152024-10-11.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:16, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car162024-10-11.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:17, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car172024-10-11.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:18, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car182024-10-11.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:19, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car192024-10-11.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:20, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car202024-10-11.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:21, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car212024-10-11.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:22, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car222024-10-11.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:23, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car232024-10-11.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:24, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car242024-10-11.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:25, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car252024-10-11.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:26, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car262024-10-11.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:27, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car272024-10-11.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:28, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car282024-10-11.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:29, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car292024-10-11.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:30, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car302024-10-11.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:31, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car312024-10-11.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:32, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car322024-10-11.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:33, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car332024-10-11.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:34, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car342024-10-11.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:35, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car352024-10-11.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:36, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car362024-10-11.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:37, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car372024-10-11.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:38, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car382024-10-11.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:39, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car392024-10-11.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:40, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car402024-10-11.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:41, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car412024-10-11.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:42, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car422024-10-11.jpeg',timeIn: '', timeOut: '', timeSpent: '' },
{ id:43, objectId: '', objectImage: 'https://storage.googleapis.com/emoeatsmenuimages/car432024-10-11.jpeg',timeIn: '', timeOut: '', timeSpent: '' },


];

}

else if (formattedDate === '12/10/2024') {
  return [



];

}
else {
                return [];
            }
        };

        setTableData(fetchDataForDate(selectedDate));
    }, [selectedDate]);

    const cardStyle = {
        width: '400px', // Fixed width
        bgcolor: "#FFFFFF"
    };

    const mediaWrapperStyle = {
        position: 'relative',
        height: '300px', // Fixed height for the media
    };

    const mediaStyle = {
        height: '100%',
        objectFit: 'cover' // Adjust this as needed
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    return (
        <>
          <Grid container my={4} spacing={2} sx={{ justifyContent: 'center' }}>
            {cameras.map((camera) => (
              <Box key={camera.CameraId} sx={cardStyle}>
                <Card>
                  <Box sx={mediaWrapperStyle}>
                    <CardMedia
                      component="iframe" // Use iframe for video streaming
                      src={camera.rtspmeUrl}
                      title={camera.CameraName}
                      sx={mediaStyle}
                    />
                  </Box>
                  <CardContent>
                    <Typography gutterBottom variant='h6' component='div'>
                      {camera.CameraName}
                    </Typography>
                  </CardContent>
                </Card>
              </Box>
            ))}
          </Grid>
    
          <Box my={4} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6" component="div">
              Today's Footfall Count: {tableData.length}
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Select Date"
                value={selectedDate}
                onChange={(newValue) => handleDateChange(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Box>
    
          <Box sx={{ my: 4, display: 'flex', justifyContent: 'flex-end' }}>
            <TextField
              label="Search by Object ID"
              variant="outlined"
              value={searchTerm}
              onChange={handleSearchChange}
              sx={{ mb: 2, width: 300 }}
            />
          </Box>
    
          <TableContainer component={Paper} sx={{ marginTop: 4 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Object ID</TableCell>
                  <TableCell>Object Image</TableCell>
                  <TableCell>Time In</TableCell>
                  <TableCell>Time Out</TableCell>
                  <TableCell>Time Spent</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.length > 0 ? (
                  filteredData.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.objectId}</TableCell>
                      <TableCell><img src={row.objectImage} alt={row.objectId} style={{ width: 50, height: 50 }} /></TableCell>
                      <TableCell>{row.timeIn}</TableCell>
                      <TableCell>{row.timeOut}</TableCell>
                      <TableCell>{row.timeSpent}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} align="center">Data not available</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      );
    }